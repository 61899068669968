// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card{
  margin:0 !important;
  padding: 20px;
}

ion-card-header {
  text-align: center;
}

@media (max-width: 576px) {
  ion-card{
    padding: 5px;
  }
}

ion-button{
  font-weight: 600;
}

ion-card-title {
  font-size: 24px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFjY2VwdGFuY2UtbW9kYWwuY29tcG9uZW50LmNzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLG1CQUFtQjtFQUNuQixhQUFhO0FBQ2Y7O0FBRUE7RUFDRSxrQkFBa0I7QUFDcEI7O0FBRUE7RUFDRTtJQUNFLFlBQVk7RUFDZDtBQUNGOztBQUVBO0VBQ0UsZ0JBQWdCO0FBQ2xCOztBQUVBO0VBQ0UsZUFBZTtBQUNqQiIsImZpbGUiOiJhY2NlcHRhbmNlLW1vZGFsLmNvbXBvbmVudC5jc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpb24tY2FyZHtcclxuICBtYXJnaW46MCAhaW1wb3J0YW50O1xyXG4gIHBhZGRpbmc6IDIwcHg7XHJcbn1cclxuXHJcbmlvbi1jYXJkLWhlYWRlciB7XHJcbiAgdGV4dC1hbGlnbjogY2VudGVyO1xyXG59XHJcblxyXG5AbWVkaWEgKG1heC13aWR0aDogNTc2cHgpIHtcclxuICBpb24tY2FyZHtcclxuICAgIHBhZGRpbmc6IDVweDtcclxuICB9XHJcbn1cclxuXHJcbmlvbi1idXR0b257XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxufVxyXG5cclxuaW9uLWNhcmQtdGl0bGUge1xyXG4gIGZvbnQtc2l6ZTogMjRweDtcclxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/acceptance-modal/acceptance-modal.component.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;AACA,ozBAAozB","sourcesContent":["ion-card{\r\n  margin:0 !important;\r\n  padding: 20px;\r\n}\r\n\r\nion-card-header {\r\n  text-align: center;\r\n}\r\n\r\n@media (max-width: 576px) {\r\n  ion-card{\r\n    padding: 5px;\r\n  }\r\n}\r\n\r\nion-button{\r\n  font-weight: 600;\r\n}\r\n\r\nion-card-title {\r\n  font-size: 24px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
