import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesizePipe } from './file-size.pipe';
import { StatusToESPipe } from './status-es.pipe';
import { AddressOrderPipe } from './address-order.pipe';
import { Obj2ArrPipe } from './obj2arr.pipe';
import { SafeValuePipe } from './safe-value.pipe';



@NgModule({
  declarations: [
    FilesizePipe, StatusToESPipe, AddressOrderPipe, Obj2ArrPipe, SafeValuePipe],
  imports: [
    CommonModule
  ],
  exports: [FilesizePipe, StatusToESPipe, AddressOrderPipe, Obj2ArrPipe, SafeValuePipe]
})
export class PipesModule { }
