import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip-info-pages-per-sheet',
  templateUrl: './tooltip-info-pages-per-sheet.component.html',
  styleUrls: ['./tooltip-info-pages-per-sheet.component.scss'],
})
export class TooltipInfoPagesPerSheetComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
