import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { EncryptionService } from "../shared/encryption.service";

@Injectable({
  providedIn: 'root'
})
export class DevelopmentGuard  {
  constructor(private router: Router, private encrypt: EncryptionService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (environment.production) {
      return true;
    } else {
      try {
        const logged = this.encrypt.decrypt(localStorage.getItem('devLogin'));
        if (logged === 'true') {
          return true;
        } else {
          this.router.navigate(['/development-login']);
        }
      } catch (e) {
        this.router.navigate(['/development-login']);
      }
    }
    return false;
  }

}
