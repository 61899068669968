import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from "@angular/forms";

@Directive({
  selector: '[validateDNI]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidateDNI, multi: true}]
})
export class ValidateDNI implements Validator {
  @Input('validateDNI') validateDNI = '';

  validate(control: AbstractControl): ValidationErrors | null {
    // console.log("validating DNI...");
    return validateDNIValidator()(control);
  }
}

export function validateDNIValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const ERR = {validateDNI: {value: control.value}};
    if(control.value == '') return null;
    if(control.value == null) return null;
    
    const is_DNI = check_DNI(control.value);
    const is_CIF = isValidCif(control.value);
    const is_NIE = check_NIE(control.value);
    // console.log(control.value,{is_DNI,is_CIF,is_NIE});
    const isValid = is_DNI || is_CIF || is_NIE;
    return isValid ? null : ERR;
  };
}

export const ES_AVAILABLE_LETTERS = ['T','R','W','A','G','M','Y','F','P','D','X','B','N','J','Z','S','Q','V','H','L','C','K','E'];
export const ES_AVAILABLE_LETTERS_REMAINDER = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22]


export const DNI_NUMBERS_LENGHT = 8;
export const DNI_LENGHT_WITH_LETTER = DNI_NUMBERS_LENGHT + 1;

export function check_DNI(dni:string):boolean{
  // TODO revisar =>>>>> dni[0].match(/[KLM]/)
  let result = true;
  result = result && dni.length === DNI_LENGHT_WITH_LETTER;
  if(result){
    let dni_splitted = dni.split('');
    let dni_letter = dni_splitted.pop(); // Tomamos la letra
    let dni_code = +(dni_splitted.join('')); // Tomamos los numeros
    result = result &&  verify_ES_Letter(dni_letter,dni_code);
    // console.log({ dni_splitted,  dni_letter,  dni_code, dni_index, dni_verification_letter,});
  }

  return result;
}

export function verify_ES_Letter(letter:string, code): boolean {
  let index = code % ES_AVAILABLE_LETTERS.length; //obtenemos el indice "para buscar" la letra
  let verified_letter = ES_AVAILABLE_LETTERS[ES_AVAILABLE_LETTERS_REMAINDER[index]];
  // console.log({letter, verified_letter, code, index});
  return ES_AVAILABLE_LETTERS.includes(letter) && verified_letter === letter;
}


export const NIE_INIT_LETTERS = ["X","Y","Z"];
export const NIE_INIT_LETTERS_REMAINDER = [0,1,2]
export const NIE_NUMBERS_LENGHT = 7;
export const NIE_LENGHT = 1 + NIE_NUMBERS_LENGHT + 1;

export function check_NIE(nie:string):boolean{
  let result = true;
  result = result && nie.length === NIE_LENGHT;

  if(result){
    let nie_splitted = nie.split('');
    let nie_letter_first = nie_splitted[0]; // Tomamos el primer caracter
    let nie_letter_last = nie_splitted.pop(); // Tomamos la última letra
    let nie_code_letter_first = NIE_INIT_LETTERS_REMAINDER[NIE_INIT_LETTERS.indexOf(nie_letter_first)];

    //Si encuentra el valor de la primera letra
    if(nie_code_letter_first !== undefined){
      nie_splitted[0] = nie_code_letter_first.toString(); // transformamos la primera letra en un valor
      let nie_code = +(nie_splitted.join('')); // Tomamos los numeros
      result = result && verify_ES_Letter(nie_letter_last,nie_code);
    }else{
      result = false;
    }
    // console.log(result,{nie,nie_splitted,nie_letter_first,nie_letter_last,nie_code_letter_first,nie_code});
  }

  return result;
}



// http://www.jagar.es/economia/ccif.htm
export const CIF_STRUCTRURE = [
  'T', // Letra de tipo de Organización,
  'P', // Código provincial.
  'P',
  'N', // Numeración secuencial dentro de la provincia.
  'N',
  'N',
  'N',
  'N',
  'C' // Dígito de control, un número o letra: Aó1, Bó2, Có3, Dó4, Eó5, Fó6,Gó7, Hó8, Ió9, Jó0.
];
export const CIF_STRUCTURE_T =[
  'A',// - Sociedad Anónima.
  'B',// - Sociedad de responsabilidad limitada.
  'C',// - Sociedad colectiva.
  'D',// - Sociedad comanditaria.
  'E',// - Comunidad de bienes y herencias yacentes.
  'F',// - Sociedad cooperativa.
  'G',// - Asociaciones.
  'H',// - Comunidad de propietarios en régimen de propiedad horizontal.
  'J',// - Sociedades Civiles, con o sin personalidad jurídica.
  'K',// - Formato antiguo, en desuso.
  'L',// - Formato antiguo, en desuso.
  'M',// - Formato antiguo, en desuso.
  'N',// - Entidades extranjeras.
  'P',// - Corporación local.
  'Q',// - Organismo público.
  'R',// - Congregaciones e Instituciones Religiosas.
  'S',// - Órganos de la Administración del Estado y Comunidades Autónomas.
  'U',// - Uniones temporales de Empresas.
  'V',// - Otros tipos no definidos en el resto de claves.
  'W',// - Establecimientos permanentes de entidades no residentes en España.
]
export const CIF_STRUCTURE_C  = [
  'J', 
  'A', 
  'B', 
  'C', 
  'D', 
  'E', 
  'F', 
  'G', 
  'H', 
  'I'
];
export const CIF_T_LENGHT = 1;
export const CIF_P_LENGHT = 2;
export const CIF_N_LENGHT = 5;
export const CIF_C_LENGHT = 1;
export const CIF_LENGHT = CIF_T_LENGHT + CIF_P_LENGHT + CIF_N_LENGHT + CIF_C_LENGHT;

// 1 letra, 7 dígitos, 1 letra/dígito  
// export function check_CIF(cif:string) {
//   let result = true;
//   result = result && cif.length === CIF_LENGHT;

//   if(result){
//     // let cif_splitted = cif.split('');
//     let T = cif.slice(0, CIF_T_LENGHT); // Letra de tipo de Organización,
//     let P = cif.slice(1,3);// Código provincial.
//     let N = cif.slice(3,CIF_LENGHT-1); // Numeración secuencial dentro de la provincia.
//     let C = cif[cif.length -1];// Dígito de control,
//     let _numbers_string = (P + N);
//     let _numbers = +_numbers_string;

//     //# Check T
//     result = result && CIF_STRUCTURE_T.includes(T);

//     //# Check P
//     result = result && CIF_PROVINCE_CODES.includes(P);
    
//     //# Check N 
//     //# Check :_numbers 
//     result = result && !isNaN(_numbers);

//     //Example: A58818501
//     var even_sum = 0;
//     var odd_sum = 0;
//     var n;
//     for ( var i = 0; i < _numbers_string.length; i++) {
//       n = parseInt( _numbers_string[i], 10 );
//       if ( i % 2 === 0 ) {
//         n *= 2;
//         odd_sum += n < 10 ? n : n - 9;
//       } else {
//         even_sum += n;
//       }
//     }
//     var partial_sum = even_sum + odd_sum;
//     var control_digit = 10 - (partial_sum % 10);
//     if(control_digit >= 10) control_digit -= 10;
//     let test = String.fromCharCode(64+ (10 - (partial_sum % 10)));
//     console.log(test);
    
//     var control_digit_letter = CIF_STRUCTURE_C[control_digit];
    
//     //# Check C 
//     result = result && (control_digit_letter === C || control_digit === +C);
    
//     console.log("CIF CHECK",[
//       result,
//       cif,
//       {T},
//       {P},
//       {N},
//       {C},
//       {_numbers_string},
//       {_numbers},
//       {even_sum},
//       {odd_sum},
//       {n},
//       {partial_sum},
//       {control_digit}, 
//       {control_digit_letter}, 
//     ]);
//   }

//   return result;
// }



// https://jsfiddle.net/juglan/rexdzh6v/
function isValidCif(cif) {
	if (!cif || cif.length !== 9) {
		return false;
	}

	var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
	var digits = cif.substr(1, cif.length - 2);
	var letter = cif.substr(0, 1);
	var control = cif.substr(cif.length - 1);
	var sum = 0;
  var i;
	var digit:any;

	if (!letter.match(/[A-Z]/)) {
		return false;
	}

	for (i = 0; i < digits.length; ++i) {
		digit = parseInt(digits[i]);

		if (isNaN(digit)) {
			return false;
		}

		if (i % 2 === 0) {
			digit *= 2;
			if (digit > 9) {
				digit = parseInt(""+(digit / 10)) + (digit % 10);
			}

			sum += digit;
		} else {
			sum += digit;
		}
	}

	sum %= 10;
	if (sum !== 0) {
		digit = 10 - sum;
	} else {
		digit = sum;
	}

	if (letter.match(/[ABEH]/)) {
		return String(digit) === control;
	}
	if (letter.match(/[NPQRSW]/)) {
		return letters[digit] === control;
	}

	return String(digit) === control || letters[digit] === control;

}

export const CIF_PROVINCE_CODES = [
  '01',          // - Álava.
  ,'02',          // - Albacete.
  ,'03', '53', '54',          // - Alicante.
  ,'04',          // - Almería.
  ,'05',          // - Ávila.
  ,'06',          // - Badajoz.
  ,'07', '57',          // - Islas Baleares.
  ,'08', '58', '59', '60', '61', '62', '63', '64',          // - Barcelona.
  ,'09',          // - Burgos.
  ,'10',          // - Cáceres.
  ,'11', '72',          // - Cádiz.
  ,'12',          // - Castellón.
  ,'13',          // - Ciudad Real.
  ,'14', '56',          // - Córdoba.
  ,'15', '70',          // - A Coruña.
  ,'16',          // - Cuenca.
  ,'17', '55',          // - Girona.
  ,'18',          // - Granada.
  ,'19',          // - Guadalajara.
  ,'20', '71',          // - Guipúzcoa.
  ,'21',          // - Huelva.
  ,'22',          // - Huesca.
  ,'23',          // - Jaén.
  ,'24',          // - León.
  ,'25',          // - Lleida.
  ,'26',          // - La Rioja.
  ,'27',          // - Lugo.
  ,'28', '78', '79', '80', '81', '82', '83', '84', '85',          // - Madrid.
  ,'29', '92', '93',          // - Málaga.
  ,'30', '73',          // - Murcia.
  ,'31',          // - Navarra.
  ,'32',          // - Ourense.
  ,'33', '74',          // - Asturias.
  ,'34',          // - Palencia.
  ,'35', '76',          // - Las Palmas.
  ,'36', '94',          // - Pontevedra.
  ,'37',          // - Salamanca.
  ,'38', '75',          // - Santa Cruz de Tenerife.
  ,'39',          // - Cantabria.
  ,'40',          // - Segovia.
  ,'41', '91',          // - Sevilla.
  ,'42',          // - Soria.
  ,'43', '77',          // - Tarragona.
  ,'44',          // - Teruel.
  ,'45',          // - Toledo.
  ,'46', '96', '97', '98',          // - Valencia.
  ,'47',          // - Valladolid.
  ,'48', '95',          // - Vizcaya.
  ,'49',          // - Zamora.
  ,'50', '99',          // - Zaragoza.
  ,'51',          // - Ceuta.
  ,'52'          // - Melilla.
]


export const check_Nif = function()
{
  // https://es.wikipedia.org/wiki/N%C3%BAmero_de_identificaci%C3%B3n_fiscal
  //  return /^(\d{8})([A-HJ-NP-TV-Z])$/.test(this) && ("TRWAGMYFPDXBNJZSQVHLCKE"[(RegExp.$1%23)]==RegExp.$2);
};