import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VariantChipsComponent } from './variant-chips.component';
import { IonicModule } from '@ionic/angular';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from '@cloudfactorydk/ng2-tooltip-directive';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    FlexLayoutModule,
    TooltipModule
  ],
  exports: [VariantChipsComponent],
  declarations: [	VariantChipsComponent]
})
export class VariantChipsModule { }
