import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { DesktopHeaderComponentModule } from 'src/app/shared/desktop-header/desktop-header.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QtyStepperModule } from 'src/app/shared/shop/qty-stepper/qty-stepper.module';
import { VariantChipsModule } from 'src/app/shared/shop/variant-chips/variant-chips.module';
import { ProductVariantsModule } from '../product-variants/product-variants.module';
import { ProductPartInfoInteractionComponent } from './product-part-info-interaction.component';
import { RouterModule } from '@angular/router';
import { ProductLabelModule } from 'src/app/shared/shop/product-card/product-label/product-label.module';
import { ProductCustomElementsComponent } from "../product-custom-elements/product-custom-elements.component";
import { TooltipModule } from "@cloudfactorydk/ng2-tooltip-directive";
import { ProductsInPackComponent } from '../products-in-pack/products-in-pack.component';

@NgModule({
  imports: [
    FlexLayoutModule,
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule,
    DesktopHeaderComponentModule,
    FlexLayoutModule,
    QtyStepperModule,
    ProductVariantsModule,
    ProductLabelModule,
    VariantChipsModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  declarations: [ProductPartInfoInteractionComponent, ProductCustomElementsComponent, ProductsInPackComponent],
  exports: [ProductPartInfoInteractionComponent, ProductCustomElementsComponent]
})
export class ProductPartInfoInteractionComponentModule {
}
