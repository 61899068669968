// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-container {
  color: black;
  overflow-y: scroll;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkZC1kb2N1bWVudC1wb3B1cC5jb21wb25lbnQuc2NzcyIsIi4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcQ29weWZseSUyMDJcXERlc2t0b3BcXERlc2Fycm9sbG9cXGNvcGlzdGVyaWEtYXBwLWlvbmljXFxzcmNcXGFwcFxcY3JlYXRvcnNcXGFkZC1kb2N1bWVudC1wb3B1cFxcYWRkLWRvY3VtZW50LXBvcHVwLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNBO0VBQ0UsWUFBQTtFQUNBLGtCQUFBO0FDQUYiLCJmaWxlIjoiYWRkLWRvY3VtZW50LXBvcHVwLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiXHJcbi5wb3B1cC1jb250YWluZXJ7XHJcbiAgY29sb3I6IGJsYWNrO1xyXG4gIG92ZXJmbG93LXk6IHNjcm9sbDtcclxufSIsIi5wb3B1cC1jb250YWluZXIge1xuICBjb2xvcjogYmxhY2s7XG4gIG92ZXJmbG93LXk6IHNjcm9sbDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/creators/add-document-popup/add-document-popup.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/creators/add-document-popup/add-document-popup.component.scss"],"names":[],"mappings":"AACA;EACE,YAAA;EACA,kBAAA;ACAF;ADCA,orBAAorB","sourcesContent":["\r\n.popup-container{\r\n  color: black;\r\n  overflow-y: scroll;\r\n}",".popup-container {\n  color: black;\n  overflow-y: scroll;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
