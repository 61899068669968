import { Finishing, PageSize, PageTurn, PaperWeight, Settings } from "../../models/models";

// export const _BindingSheetLimit = 400;

// // weight is not used in server, only in app
// export const _BindingData = {
//   coverWeight: [
//     {
//       // sheetsRange: [0, 150], // ( ]
//       diameterRange: [0, 20], // ( ] ring diameter mm
//       pageSize: {
//         // [PageSize.A4]: 34.5, // weight in grams
//         [PageSize.A4]: 48.00, // weight in grams
//         [PageSize.A5]: 24.00,
//       }
//     },
//     {
//       // sheetsRange: [150, BindingSheetLimit],
//       diameterRange: [20, 50], // ( ] ring diameter mm
//       pageSize: {
//         [PageSize.A4]: 48.00,
//         [PageSize.A5]: 24.00,
//       }
//     },
//   ],
//   ringWeight: [
//     {
//       diameter: 6, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [0, 24], // ( ] sheetsRange
//         [PaperWeight.W100]: [0, 18],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 3.55, // weight in grams
//           [PageTurn.SHORTSIDE]: 2.37,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 2.37,
//           [PageTurn.SHORTSIDE]: 1.77,
//         },
//       },
//     },
//     {
//       diameter: 8, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [24, 50], // ( ] sheetsRange
//         [PaperWeight.W100]: [18, 44],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 4.64, // weight in grams
//           [PageTurn.SHORTSIDE]: 3.09,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 3.09,
//           [PageTurn.SHORTSIDE]: 2.32,
//         },
//       },
//     },
//     // {
//     //   diameter: 10, // mm
//     //   paperWeight: {
//     //     [PaperWeight.W80]: [44, 62], // ( ] sheetsRange
//     //     [PaperWeight.W100]: [38, 56],
//     //   },
//     //   pageSize: {
//     //     [PageSize.A4]: {
//     //       [PageTurn.LARGESIDE]: 7.05, // weight in grams
//     //       [PageTurn.SHORTSIDE]: 4.70,
//     //     },
//     //     [PageSize.A5]: {
//     //       [PageTurn.LARGESIDE]: 4.70,
//     //       [PageTurn.SHORTSIDE]: 3.53,
//     //     },
//     //   },
//     // },
//     // {
//     //   diameter: 12, // mm
//     //   paperWeight: {
//     //     [PaperWeight.W80]: [62, 80], // ( ] sheetsRange
//     //     [PaperWeight.W100]: [56, 74],
//     //   },
//     //   pageSize: {
//     //     [PageSize.A4]: {
//     //       [PageTurn.LARGESIDE]: 8.65, // weight in grams
//     //       [PageTurn.SHORTSIDE]: 5.76,
//     //     },
//     //     [PageSize.A5]: {
//     //       [PageTurn.LARGESIDE]: 5.76,
//     //       [PageTurn.SHORTSIDE]: 4.32,
//     //     },
//     //   },
//     // },
//     {
//       diameter: 12, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [50, 80], // ( ] sheetsRange
//         [PaperWeight.W100]: [44, 74],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 8.10, // weight in grams
//           [PageTurn.SHORTSIDE]: 5.40,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 5.40,
//           [PageTurn.SHORTSIDE]: 4.05,
//         },
//       },
//     },
//     {
//       diameter: 14, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [80, 97], // ( ] sheetsRange
//         [PaperWeight.W100]: [74, 89],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 10.47, // weight in grams
//           [PageTurn.SHORTSIDE]: 6.98,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 6.98,
//           [PageTurn.SHORTSIDE]: 5.23,
//         },
//       },
//     },
//     {
//       diameter: 16, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [97, 114], // ( ] sheetsRange
//         [PaperWeight.W100]: [89, 104],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 13.29, // weight in grams
//           [PageTurn.SHORTSIDE]: 8.86,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 8.86,
//           [PageTurn.SHORTSIDE]: 6.64,
//         },
//       },
//     },
//     {
//       diameter: 18, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [114, 131], // ( ] sheetsRange
//         [PaperWeight.W100]: [104, 118],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 16.20, // weight in grams
//           [PageTurn.SHORTSIDE]: 10.80,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 10.80,
//           [PageTurn.SHORTSIDE]: 8.10,
//         },
//       },
//     },
//     {
//       diameter: 20, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [131, 148], // ( ] sheetsRange
//         [PaperWeight.W100]: [118, 132],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 16.56, // weight in grams
//           [PageTurn.SHORTSIDE]: 11.04,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 11.04,
//           [PageTurn.SHORTSIDE]: 8.28,
//         },
//       },
//     },
//     {
//       diameter: 23, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [148, 172], // ( ] sheetsRange
//         [PaperWeight.W100]: [132, 153],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 20.93, // weight in grams
//           [PageTurn.SHORTSIDE]: 13.95,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 13.95,
//           [PageTurn.SHORTSIDE]: 10.47,
//         },
//       },
//     },
//     {
//       diameter: 25, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [172, 196], // ( ] sheetsRange
//         [PaperWeight.W100]: [153, 174],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 23.21, // weight in grams
//           [PageTurn.SHORTSIDE]: 15.47,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 15.47,
//           [PageTurn.SHORTSIDE]: 11.60,
//         },
//       },
//     },
//     {
//       diameter: 28, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [196, 216], // ( ] sheetsRange
//         [PaperWeight.W100]: [174, 196],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 26.30, // weight in grams
//           [PageTurn.SHORTSIDE]: 17.53,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 17.53,
//           [PageTurn.SHORTSIDE]: 13.15,
//         },
//       },
//     },
//     {
//       diameter: 32, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [216, 264], // ( ] sheetsRange
//         [PaperWeight.W100]: [196, 228],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 29.94, // weight in grams
//           [PageTurn.SHORTSIDE]: 19.96,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 19.96,
//           [PageTurn.SHORTSIDE]: 14.97,
//         },
//       },
//     },
//     {
//       diameter: 35, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [264, 286], // ( ] sheetsRange
//         [PaperWeight.W100]: [228, 248],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 36.67, // weight in grams
//           [PageTurn.SHORTSIDE]: 24.45,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 24.45,
//           [PageTurn.SHORTSIDE]: 18.34,
//         },
//       },
//     },
//     {
//       diameter: 38, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [286, 312], // ( ] sheetsRange
//         [PaperWeight.W100]: [248, 280],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 41.41, // weight in grams
//           [PageTurn.SHORTSIDE]: 27.60,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 27.60,
//           [PageTurn.SHORTSIDE]: 20.70,
//         },
//       },
//     },
//     {
//       diameter: 42, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [312, 336], // ( ] sheetsRange
//         [PaperWeight.W100]: [280, 316],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 45.86, // weight in grams
//           [PageTurn.SHORTSIDE]: 30.57,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 30.57,
//           [PageTurn.SHORTSIDE]: 22.93,
//         },
//       },
//     },
//     {
//       diameter: 45, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [336, 352], // ( ] sheetsRange
//         [PaperWeight.W100]: [316, 340],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 51.69, // weight in grams
//           [PageTurn.SHORTSIDE]: 34.46,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 34.46,
//           [PageTurn.SHORTSIDE]: 25.84,
//         },
//       },
//     },
//     {
//       diameter: 50, // mm
//       paperWeight: {
//         [PaperWeight.W80]: [352, 400], // ( ] sheetsRange
//         [PaperWeight.W100]: [340, 400],
//       },
//       pageSize: {
//         [PageSize.A4]: {
//           [PageTurn.LARGESIDE]: 55.06, // weight in grams
//           [PageTurn.SHORTSIDE]: 36.70,
//         },
//         [PageSize.A5]: {
//           [PageTurn.LARGESIDE]: 36.70,
//           [PageTurn.SHORTSIDE]: 27.53,
//         },
//       },
//     },
//   ],
// };

export class BindingHelper {
  constructor(bindingData) {
    this.BindingData = bindingData;
  }

  BindingData: any;

  // returns: { diameter, qty, split, weight }
  findBindingRing(sheetCount = 0, pageSize = PageSize.A4, paperWeight = PaperWeight.W80, pageTurn = PageTurn.LARGESIDE,
                  extraprices = null, file = null) {
    // make an average, more than one binding
    let qty = Math.ceil(sheetCount / this.BindingData.sheetLimit[paperWeight]);
    let split = qty > 1;
    let _sheetCount = qty > 1
      ? Math.ceil(sheetCount / qty) // this may increment the diameter needlessly :-/
      : sheetCount;
    let diameter = 0;
    let weight = 0;
    // find ring diameter for this sheet count
    const ringData = this.BindingData.ringWeight.find(e => {
      return e.paperWeight[paperWeight][0] < _sheetCount && _sheetCount <= e.paperWeight[paperWeight][1];
      // return e.sheetsRange[0] < _sheetCount && _sheetCount <= e.sheetsRange[1];
    });
    if (ringData) {
      diameter = ringData.diameter;
    }

    // find cover weight for this ring diameter
    const coverData = this.BindingData.coverWeight.find(e => {
      return e.diameterRange[0] < diameter && diameter <= e.diameterRange[1];
    });

    // calculate weight
    if (ringData && coverData) {
      let coverWeight = coverData.pageSize[pageSize];
      //Si es PPL se quita el peso de una tapa y se añade el de la funda plastificada
      if (file && file.coverLaminated) {
        const coverLaminatedWeight = (extraprices.finishing[Finishing.LAMINATED].variants.find(el => el.options.laminationType === file.laminationType
          && el.options.laminationWeight === file.laminationWeight &&
          el.options.pageSize === pageSize).weight)
        coverWeight = (coverWeight / 2) + coverLaminatedWeight

      }
      // console.log("Coverdata", coverData)
      const _weight = ringData.pageSize[pageSize][pageTurn] + coverWeight;
      weight = _weight * qty;
    }

    return { diameter, qty, split, weight };
  }
}
