import { Component, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'app-confirm-comment-modal',
  templateUrl: './confirm-comment-modal.component.html',
  styleUrls: ['./confirm-comment-modal.component.scss'],
})
export class ConfirmCommentModalComponent implements OnInit {
  checked = false;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  cancel() {
    this.modalController.dismiss()
  }

  submit() {
    this.modalController.dismiss('submit')
  }

}
