import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-variant-chips',
  templateUrl: './variant-chips.component.html',
  styleUrls: ['./variant-chips.component.scss']
})
export class VariantChipsComponent implements OnInit {
  @Input() options = [];
  @Input() selected = null;
  @Input() name = "option";
  @Input() available = [];
  @Input() galleries;
  @Input() variants;
  @Output() changes = new EventEmitter<any>();
  @Output() nextStep = new EventEmitter<any>()
  @Input() isProductCard: boolean = false;


  constructor() {
  }

  ngOnInit() {
    
  }

  ngAfterViewInit() {
    window.addEventListener('load', this.checkOverFlow);
    window.addEventListener('resize', this.checkOverFlow);
    this.checkOverFlow();
  }

  onChange(value) {
    // console.log("Variant1", value);
    // console.log("selected", this.selected);
    this.changes.emit({ option: value })
  }

  isDisabled(option) {
    return this.available ? !this.available.includes(option) : true;
  }

  clickInput(id: string) {
    this.nextStep.emit();

    document.getElementById(id).click();
  }

  getVariantDiscount(option: any) {
    const variant = this.variants.find(v => v.optionsValue[0] === option);
    return variant ? variant.discount : 0;
  }

  checkOverFlow() {
    const container = document.querySelector('.selection-group') as HTMLDivElement;

    if (!container) return;

    if (container.scrollHeight > container.clientHeight) {
      container.style.overflowY = 'auto'
    } else {
      container.style.overflowY = 'unset'
    }
  }
  
}
