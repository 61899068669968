// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  font-size: 3em;
}

p {
  font-size: 1.3em;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm1haW50ZW5hbmNlLnBhZ2Uuc2NzcyIsIi4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcQ29weWZseSUyMDJcXERlc2t0b3BcXERlc2Fycm9sbG9cXGNvcGlzdGVyaWEtYXBwLWlvbmljXFxzcmNcXGFwcFxcbWFpbnRlbmFuY2VcXG1haW50ZW5hbmNlXFxtYWludGVuYW5jZS5wYWdlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxjQUFBO0FDQ0Y7O0FEQ0E7RUFDRSxnQkFBQTtBQ0VGIiwiZmlsZSI6Im1haW50ZW5hbmNlLnBhZ2Uuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImgxIHtcclxuICBmb250LXNpemU6IDNlbTtcclxufVxyXG5wIHtcclxuICBmb250LXNpemU6IDEuM2VtO1xyXG59XHJcblxyXG4ubG9nbyB7XHJcbiAgLy8gbWF4LWhlaWdodDogMjAwcHg7XHJcbiAgLy8gbWF4LXdpZHRoOiAzMDBweDtcclxuICAvLyB3aWR0aDogMzAwcHg7XHJcblxyXG4gXHJcbiAgaW1ne1xyXG4gICAgLy8gd2lkdGg6MTAwJTtcclxuICAgIC8vIG9iamVjdC1maXQ6IGNvdmVyO1xyXG4gICAgLy8gb2JqZWN0LXBvc2l0aW9uOnRvcDtcclxuICB9XHJcbn0iLCJoMSB7XG4gIGZvbnQtc2l6ZTogM2VtO1xufVxuXG5wIHtcbiAgZm9udC1zaXplOiAxLjNlbTtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/maintenance/maintenance/maintenance.page.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/maintenance/maintenance/maintenance.page.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;ACCF;;ADCA;EACE,gBAAA;ACEF;ADCA,g5BAAg5B","sourcesContent":["h1 {\r\n  font-size: 3em;\r\n}\r\np {\r\n  font-size: 1.3em;\r\n}\r\n\r\n.logo {\r\n  // max-height: 200px;\r\n  // max-width: 300px;\r\n  // width: 300px;\r\n\r\n \r\n  img{\r\n    // width:100%;\r\n    // object-fit: cover;\r\n    // object-position:top;\r\n  }\r\n}","h1 {\n  font-size: 3em;\n}\n\np {\n  font-size: 1.3em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
