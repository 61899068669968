import { Component, Input, OnInit } from '@angular/core';
import { Settings } from "../../../models/models";
import { BindingHelper } from "../../../shared/helpers/binding";
import { ProductService } from "../../../shared/product.service";

@Component({
  selector: 'app-tooltip-info-binding',
  templateUrl: './tooltip-info-binding.component.html',
  styleUrls: ['./tooltip-info-binding.component.scss'],
})
export class TooltipInfoBindingComponent implements OnInit {
  @Input() coverLaminated;
  @Input() settings;

  constructor(private productService: ProductService) {
  }

  ngOnInit() {
  }

  protected readonly Settings = Settings;

  getCurrentPaperWeight() {
    return this.settings[Settings.PAPER_WEIGHT].options[this.settings[Settings.PAPER_WEIGHT].selected - 1].value;
  }

  getSheetLimit() {
    return this.productService.bindingHelper.BindingData.sheetLimit[this.getCurrentPaperWeight()];
  }
}
