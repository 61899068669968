import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AddCouponComponent } from './add-coupon/add-coupon.component';
import { IonicModule } from '@ionic/angular';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
  declarations: [AddCouponComponent],
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    FlexModule,
  ],
  exports: [AddCouponComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CouponsModule { }
