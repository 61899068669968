// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h4 {
  font-size: 16px;
  color: #949494;
}

p {
  font-size: 22px;
  border-radius: 20px;
  padding: 10px;
  background-color: #F6F6F6;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtaW5mby1kZXNwYWdpbmF0aW9uLmNvbXBvbmVudC5zY3NzIiwiLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcQ29weWZseSUyMDJcXERlc2t0b3BcXERlc2Fycm9sbG9cXGNvcGlzdGVyaWEtYXBwLWlvbmljXFxzcmNcXGFwcFxcY29tcG9uZW50c1xcY292ZXItc2V0dGluZ3NcXHRvb2x0aXAtaW5mby1kZXNwYWdpbmF0aW9uXFx0b29sdGlwLWluZm8tZGVzcGFnaW5hdGlvbi5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGVBQUE7RUFDQSxjQUFBO0FDQ0Y7O0FERUE7RUFDRSxlQUFBO0VBQ0EsbUJBQUE7RUFDQSxhQUFBO0VBQ0EseUJBQUE7QUNDRiIsImZpbGUiOiJ0b29sdGlwLWluZm8tZGVzcGFnaW5hdGlvbi5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImg0IHtcclxuICBmb250LXNpemU6IDE2cHg7XHJcbiAgY29sb3I6ICM5NDk0OTRcclxufVxyXG5cclxucCB7XHJcbiAgZm9udC1zaXplOiAyMnB4O1xyXG4gIGJvcmRlci1yYWRpdXM6IDIwcHg7XHJcbiAgcGFkZGluZzogMTBweDtcclxuICBiYWNrZ3JvdW5kLWNvbG9yOiAjRjZGNkY2O1xyXG59XHJcbiIsImg0IHtcbiAgZm9udC1zaXplOiAxNnB4O1xuICBjb2xvcjogIzk0OTQ5NDtcbn1cblxucCB7XG4gIGZvbnQtc2l6ZTogMjJweDtcbiAgYm9yZGVyLXJhZGl1czogMjBweDtcbiAgcGFkZGluZzogMTBweDtcbiAgYmFja2dyb3VuZC1jb2xvcjogI0Y2RjZGNjtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/cover-settings/tooltip-info-despagination/tooltip-info-despagination.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/components/cover-settings/tooltip-info-despagination/tooltip-info-despagination.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,cAAA;ACCF;;ADEA;EACE,eAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;ACCF;ADCA,4jCAA4jC","sourcesContent":["h4 {\r\n  font-size: 16px;\r\n  color: #949494\r\n}\r\n\r\np {\r\n  font-size: 22px;\r\n  border-radius: 20px;\r\n  padding: 10px;\r\n  background-color: #F6F6F6;\r\n}\r\n","h4 {\n  font-size: 16px;\n  color: #949494;\n}\n\np {\n  font-size: 22px;\n  border-radius: 20px;\n  padding: 10px;\n  background-color: #F6F6F6;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
