import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { PrintSettings, _PrintFile } from 'src/app/models/models';
import { Product } from 'src/app/models/product.model';
import { FileService } from 'src/app/shared/file.service';
import { PrintProductBlService } from 'src/app/shared/services/printProductBL';
import { ProductBlService } from 'src/app/shared/services/productBl.service';

@Component({
  selector: 'app-add-document-popup',
  templateUrl: './add-document-popup.component.html',
  styleUrls: ['./add-document-popup.component.scss']
})
export class AddDocumentPopupComponent implements OnInit {
  @Input() product;
  product$: Observable<Product>;
  pictureUrl$: Observable<string>;
  savedFile$: Observable<any>;

  settings$ = new Subject();
;
  constructor(
    private modalController: ModalController,
    private productBlService: ProductBlService,
    private printProductBlService: PrintProductBlService,
    private fileService: FileService

  ) { 
  }
   


  ngOnInit() {
    this.product$ = this.productBlService.product$(this.product.id)

    this.savedFile$ = this.printProductBlService.savedFile$;
  
    this.pictureUrl$ = combineLatest([this.product$, this.savedFile$, this.settings$]).pipe(
      map(([product, savedFile, settings])=>{
        const thumb = this.fileService.getThumbFile(savedFile as _PrintFile, settings as PrintSettings);
        if(!thumb){
          const collection = `products/${product.id}/uploads`;
          this.fileService.updateThumbnail(savedFile.id, settings, collection, true)
        }        
        return  thumb ? thumb.url :  product.pictureUrl   
      }),
      shareReplay(1)
    ) 
  }
  
  close(){
    this.dismiss();
  }

  onChanges(event){
    if (event.type === 'add'){
      this.dismiss(event.payload.qty)
    }
    if (event.type === 'color'){
      this.settings$.next(event.payload.settings)
    }    
  }

  dismiss(qty?){
    this.modalController.dismiss(
      {
        qty
      }
    );
  }
}
