import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'app-change-text-field-modal',
  templateUrl: './change-text-field-modal.component.html',
  styleUrls: ['./change-text-field-modal.component.scss'],
})
export class ChangeTextFieldModalComponent implements OnInit {
  @Input() currentText = '';
  @Input() placeholder = '';
  @Input() maxLength = 500;
  @Input() label = '';
  @Input() rows = 3;
  @Input() allowEmpty = false;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  cancel() {
    this.modalController.dismiss();
  }

  submit() {
    if (!this.currentText.length && !this.allowEmpty) {
      return
    }
    this.modalController.dismiss(({
      newText: this.currentText,
    }));
  }
}
