import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaintenanceRoutingModule } from './maintenance-routing.module';
import { MaintenancePage } from './maintenance/maintenance.page';
import { FlexLayoutModule } from '@angular/flex-layout';


@NgModule({
  declarations: [
    MaintenancePage
  ],
  imports: [
    CommonModule,
    MaintenanceRoutingModule,
    FlexLayoutModule
  ]
})
export class MaintenanceModule { }
