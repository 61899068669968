import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductVariantsComponent } from './product-variants.component';
import { IonicModule } from '@ionic/angular';
import { VariantChipsModule } from 'src/app/shared/shop/variant-chips/variant-chips.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    VariantChipsModule
  ],
  exports:[ProductVariantsComponent],
  declarations: [ProductVariantsComponent]
})
export class ProductVariantsModule { }
