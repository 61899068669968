import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedLibService {

  constructor() { }

  testInit(){
    // console.log("SHARED LIBRARY WORKS 3")
  }
}
