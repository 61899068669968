import { InfoPasarPaginaComponent } from './../../components/info-pasar-pagina/info-pasar-pagina.component';
import { Platform, PopoverController, ToastController } from '@ionic/angular';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { PrintProductStateService } from "../print-product-state.service";
import {
  TooltipInfoColorComponent
} from "../../home-new/tooltip-info-components/tooltip-info-color/tooltip-info-color.component";
import { Finishing, PaperWeight, Settings } from "../../models/models";
import {
  GroupedBindingSelectorComponent
} from "../../components/grouped-binding-selector/grouped-binding-selector.component";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-options-button',
  templateUrl: './options-button.component.html',
  styleUrls: ['./options-button.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,

})
export class OptionsButtonComponent implements OnInit {
  @Input() settings;
  @Input() color = "light";
  @Input() settingOption;
  @Input() hasImage;
  @Input() selectorType = 'buttons';
  @Output() optionSelect = new EventEmitter<any>();
  @Output() settingSelectedEvent = new EventEmitter()
  @ViewChild('container', { read: ElementRef, static: false }) containerRef: ElementRef
  // @HostListener('document:mousemove', ['$event']) 
  // onMouseMove(e) {
  //   // if(this.infoTip && (Math.abs(this.infoCoord.screenX -  e.screenX) > 22 || Math.abs(this.infoCoord.screenY -  e.screenY) > 25)){
  //   console.log("Sale A", e);
  //   if(this.infoTip && (Math.abs(this.infoCoord.screenX -  e.screenX) > 20 || Math.abs(this.infoCoord.screenY -  e.screenY) > 20)){
  //     console.log("Sale", e);
  //     this.infoTip.dismiss();
  //   }
  // }
  //setting;
  infoTip = null;
  infoCoord = {
    screenX: 0,
    screenY: 0
  }
  infoTimeout;
  isOpened: boolean = null;
  isMobile: boolean = null;

  printProduct;
  toastShown = false;

  constructor(
    public popoverController: PopoverController,
    private printProductStateService: PrintProductStateService,
    private toastController: ToastController,
    private platform: Platform,
    private cookieService: CookieService
  ) {
  }

  ngOnInit() {
    //this.setting = this.settings[this.settingOption];
    this.checkDevice();
    this.printProductStateService.printProduct$.subscribe(printProduct => {
      this.printProduct = printProduct;
    })
  }

  ngAfterViewInit() {
    this.fixContainerHeight();
  }

  private checkDevice() {
    let platforms = this.platform.platforms();
    this.isMobile = !!(platforms.find(type => type == "mobile"));
  }

  async selectOption(i, event = null) {
    if (this.settings[this.settingOption].selected === i + 1) {
      return
    }

    if (this.settings[this.settingOption].options[i].value === 'binding' && !this.cookieService.get('bindingSelected')) {
      const button = (event.currentTarget as HTMLElement); // Obtén el elemento completo del botón
      const rect = button.getBoundingClientRect(); // Obtén las coordenadas del botón
      const popover = await this.popoverController.create({
        component: GroupedBindingSelectorComponent,
        cssClass: 'groupedBindingSelectorPopover',
        event: {
          target: {
            getBoundingClientRect: () => rect // Define el rectángulo del botón completo
          }
        } as any,
        alignment: 'center', // Alinea el popover a la derecha del botón
        side: 'end', // Coloca el popover en el lado derecho
        componentProps: {
          settings: this.settings
        },
      });
      popover.onDidDismiss().then((result) => {
        if (result.data) {
          this.cookieService.set('bindingSelected', '1');
          //Set grouped
          const selectedIndex = this.settings[Settings.GROUPED].options.findIndex(option => option.value === result.data.grouped) + 1;
          this.settings[Settings.GROUPED].selected = selectedIndex;
          this.optionSelect.emit({
            setting: Settings.GROUPED,
            option: selectedIndex,
            prev: this.settings[Settings.GROUPED].selected
          });
          //Set finishing
          const prev = this.settings[this.settingOption].selected;
          this.settings[this.settingOption].selected = i + 1;
          this.optionSelect.emit({
            setting: this.settingOption,
            option: this.settings[this.settingOption].selected,
            prev: prev
          });
        }
      });
      await popover.present();


    } else {
      const prev = this.settings[this.settingOption].selected;
      this.settings[this.settingOption].selected = i + 1;
      this.optionSelect.emit({
        setting: this.settingOption,
        option: this.settings[this.settingOption].selected,
        prev: prev
      });
    }
  }

  selectOptionCheck(event) {
    const checked = event.detail.checked;
    this.selectOption(checked ? 0 : 1);
  }

  isDisabled(i) {
    let isAvailable = this.checkAvailability(i)
    if (!isAvailable && this.settings[this.settingOption].selected === i + 1) this.selectAnother();
    return !isAvailable
  }

  isHidden(i) {
    let hideSubtitleFor = this.settings[this.settingOption].options[i].hideSubtitleFor || [];
    return -1 !== hideSubtitleFor.findIndex(hidden => {
      return hidden.options.includes(this.settings[hidden.id].selected)
    });
  }

  selectAnother() {
    //buscar el primer disponible para seleccionar y sino -1
    const available = this.settings[this.settingOption].options.findIndex((ele, index) => this.checkAvailability(index))
    let setting = this.settings[this.settingOption];
    //TODO: Verificar porque se ejecuta dos veces
    setTimeout(() => {
      this.optionSelect.emit({
        setting: this.settingOption,
        option: available + 1,
        prev: this.settings[this.settingOption].selected
      });
      setting.selected = available + 1;
    }, 10);

  }

  checkAvailability(i) {
    const option = this.settings[this.settingOption].options[i];
    const isSelected = this.settings[this.settingOption].selected === i + 1;

    if (option.title === 'Grapado' && (this.isSomeCoverNotDefault() || this.isStapplingExceeded())) {
      if (isSelected && !this.toastShown) {
        let message = '';

        if (this.isStapplingExceeded()) {
          message = 'Se ha desactivado el grapado ya que la impresión de algún documento excede los 50 folios';
        } else if (this.isSomeCoverNotDefault()) {
          message = 'Se ha desactivado el grapado ya que no está disponible para configuraciones de portada distintas a la predeterminada';
        }

        if (message) {
          this.toastShown = true;
          this.toastController.create({
            message: message,
            color: 'danger',
            position: 'middle',
            cssClass: 'fitWidthToast',
            duration: 5000
          }).then(toast => {
            toast.present();

            setTimeout(() => {
              this.toastShown = false;
            }, 3000);
          });
        }
      }
      return false;
    }
    const exceptions = this.getPaperWeightAvailabilityExceptions(i);

    if (exceptions) {
      return false
    }
    const unavailableFor = option.unavailableFor || [];
    return !unavailableFor.some(unavailable =>
      unavailable.options.includes(this.settings[unavailable.id].selected)
    );
  }

  getPaperWeightAvailabilityExceptions(i) {
    const option = this.settings[this.settingOption].options[i];

    if (option.title === '300 gr' && this.settings[Settings.PAGE_SIZE].selected === 2 && this.settings[Settings.TWO_SIDED].selected === 2) {
      return true
    }

    if (['200 gr', '300 gr'].includes(option.title) && this.settings[Settings.PAGE_SIZE].selected === 3 && this.settings[Settings.TWO_SIDED].selected === 2) {
      return true
    }

    if (['160 gr', '300 gr'].includes(option.title) && this.settings[Settings.PAGE_SIZE].selected === 1 && this.settings[Settings.TWO_SIDED].selected === 2) {
      return true
    } else {
      return false
    }
  }

  async clickInfo(event: MouseEvent, type = "mouseover") {
    const popover = await this.popoverController.create({
      component: TooltipInfoColorComponent,
    });

    await popover.present();

    const { role } = await popover.onDidDismiss();
    console.log(`Popover dismissed with role: ${role}`);

  }

  public elementScrollTop(id: string): void {
    const element: HTMLElement = document.getElementById(id);
    // element.scrollTo(0,0);
    element.scrollIntoView();

  }

  public buttonSubtitle(option, i) {

    const unavailableForIndex = option.unavailableFor
      ? option.unavailableFor.findIndex(unavailable => {
        return unavailable.options.includes(this.settings[unavailable.id].selected)
      })
      : -1;
    let altSubtitle = null;

    if (this.settings[this.settingOption].options[i].title === 'GRAPADO') {
      if (this.isStapplingExceeded()) {
        altSubtitle = 'Límite excedido';
      }

      if (this.isSomeCoverColor()) {
        altSubtitle = 'Desact. Portada color';
      }
    }


    if (unavailableForIndex > -1) {
      altSubtitle = option.unavailableFor[unavailableForIndex].subTitle ?? null;
      // console.log("Availability", unavailableForIndex);
    }
    return altSubtitle ?? option?.subTitle

  }

  private isStapplingExceeded() {
    return this.printProduct.product.printingGroup.files.some(fil => fil.printSheets >= 50);
  }

  private isSomeCoverColor() {
    return this.printProduct.product.printingGroup.files.some(fil => fil.coverColor);
  }

  private isSomeCoverNotDefault() {
    return this.printProduct.product.printingGroup.files.some(fil => !fil.coverType?.default
      || fil?.coverType?.paperWeight !== this.settings[Settings.PAPER_WEIGHT].options[this.settings[Settings.PAPER_WEIGHT].selected - 1].value);
  }

  private isSomeCoverLaminated() {
    return this.printProduct.product.printingGroup.files.some(fil => fil.coverLaminated);
  }

  private fixContainerHeight() {
    // setTimeout(() => {
    //   if (this.containerRef) {
    //     const height = this.containerRef.nativeElement.offsetHeight;
    //     this.containerRef.nativeElement.style.minHeight = height + 'px'
    //   }
    // }, 500)
  }

  protected readonly Finishing = Finishing;
}
