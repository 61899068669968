import {Component, Input, OnInit} from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import {CartService} from '../cart.service';
import {Product} from '../../models/product.model';
import {ShippingType} from "../../models/shipping-settings.model";
import _Product = Product._Product;

@Component({
  selector: 'app-popup-before-pay',
  templateUrl: './popup-before-pay.component.html',
  styleUrls: ['./popup-before-pay.component.scss'],
})
export class PopupBeforePayComponent implements OnInit {

  @Input() freeShippingAmount;
  @Input() postalCode;

  storeProductsInCart: _Product[];

  constructor(
    private modalController: ModalController,
    public cartService: CartService,
    private navController: NavController
  ) {
  }

  ngOnInit(): void {
    this.storeProductsInCart = this.cartService.getStoreProductsInCart().map(item => item.product);
  }

  get quantityToFreeShipping() {
    return this.freeShippingAmount - this.cartService.shippingProductPrice;
  }

  onView() {
    this.closeDialog(true);
  }

  closeDialog(view = false) {
    this.modalController.dismiss({
      'view': view
    });
  }

  getShippingPrice() {
    return this.cartService.getShippingPrice(this.postalCode, this.cartService.cart, ShippingType.STANDARD).amt;
  }

  goToStore() {
    this.navController.navigateForward('/papeleria/catalogo');
  }
}
