import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-qty-stepper',
  templateUrl: './qty-stepper.component.html',
  styleUrls: ['./qty-stepper.component.scss']
})

export class QtyStepperComponent implements OnInit {
  @Input() size: string = 'default'
  @Input() qty: number = 0;
  @Input() min: number = 0;
  @Input() max: number = +99999999;
  @Input() showQty: boolean = false;
  @Input() disabled: boolean = false;
  @Input() whiteBackground: boolean = false;
  @Input() isGeneralSettings: boolean = false;
  @Input() isProduct: boolean = false;
  @Input() isProductCard: boolean = false;

  @Output() changes = new EventEmitter<any>()

  constructor() {
  }

  ngOnInit() {
  }

  onQtyChange(event, increment: number) {
    event.stopPropagation();
    this.qty += increment;
    this.changes.emit({ qty: this.qty });
  }

}
