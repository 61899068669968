import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from "@ionic/angular";
import { Finishing } from "../../models/models";

@Component({
  selector: 'app-grouped-binding-selector',
  templateUrl: './grouped-binding-selector.component.html',
  styleUrls: ['./grouped-binding-selector.component.scss'],
})
export class GroupedBindingSelectorComponent {
  @Input() settings

  constructor(private popoverController: PopoverController) {
  }

  protected readonly Finishing = Finishing;

  selectOption(grouped) {
    this.popoverController.dismiss({ grouped });
  }
}
