import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

import { ProductType  } from "../../models/models";
// import { AngularFireAnalytics } from '@angular/fire/compat/analytics/analytics'; // thats right?
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {EventNameString} from 'firebase/analytics'


@Component({
  selector: 'app-product-qty',
  templateUrl: './product-qty.component.html',
  styleUrls: ['./product-qty.component.scss'],
})
export class ProductQtyComponent implements OnInit {
  product;
  initialQty: number;
  recipeQty: number;
  productType = ProductType ;



  constructor(
    public navParams: NavParams,
    private popoverController: PopoverController,
    private analytics: AngularFireAnalytics,

  ) { 
    this.product = this.navParams.get('product');

    // no usar product salvo cambios de salida
    //console.log('producto', this.product, this.product.product.type);

    this.initialQty = this.product.qty;
    this.recipeQty = this.initialQty;//test

    if (this.recipeQty == 0) {
      this.recipeQty = 1;
    }
  }

  ngOnInit() {
    this.eventAction('view');
  }

  
  removeButton(){
    if ( this.recipeQty != 1 ) {
      this.recipeQty--;
    }
  }

  addButton(){
    this.recipeQty++;    
  }

  close(){
    this.dismiss(this.initialQty)
  }
  submit(){
    this.dismiss(this.recipeQty)
  }
  deleteModal(){
    this.dismiss(0)
  }

  dismiss(data){
    this.popoverController.dismiss(
      {
        qty: data,
        type: this.product.product.type
      }
    );
  }



  eventAction(actionName: string){

    const items = {
      item_id: this.product.product.id,
      item_name: this.product.product.title ,
      item_category: this.product.product.type,
      item_variant: 'black',
      item_brand: 'copyfly',
      price: this.product.product.unitPrice.amt ,
      quantity: this.product.qty
    }
    const event = {
      currency: this.product.price.cur ,
      value: this.product.price.amt,
      items: [items]
    };

    let action:EventNameString =  null;

    switch (actionName) {
      case 'view':

        action = 'view_item'
        break;
      // case 'delete': 
      //   action = firebase.analytics.EventName.REMOVE_FROM_CART
      // break;
      default:

      return;
    }

    if (action != null) {
      this.analytics.logEvent(action , event);
    }
  }
}