import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { HttpClientModule } from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { GoogleMapsModule } from "@angular/google-maps";
import { ComponentsModule } from "src/app/components/components.module";
import { CouponsModule } from "src/app/coupons/coupons.module";
import { DesktopHeaderComponentModule } from "src/app/shared/desktop-header/desktop-header.module";
import { PopupBeforePayModule } from "src/app/shared/popup-before-pay/popup-before-pay.module";
import { PromotionDialogModule } from "src/app/shared/promotion-dialog/promotion-dialog.module";
import { QtyStepperModule } from "src/app/shared/shop/qty-stepper/qty-stepper.module";
import { CartPageRoutingModule } from "./cart-routing.module";
//import { CartPageModule } from "./cart.module";
import { ItemShopProductComponent } from "./item-shop-product/item-shop-product.component";
import { ItemPrintProductComponent } from "./item-print-product/item-print-product.component";
import { ItemVendorPrintgroupComponent } from "./item-vendor-printgroup/item-vendor-printgroup.component";
import { CartPreviewComponent } from "./cart-preview/cart-preview.component";
import { RouterModule } from "@angular/router";
import { ProductListModule } from "../shop/productos/product-list/product-list.module";
import { TooltipDefaultStandardShippingComponent } from "./cart-preview/tooltip-default-standard-shipping/tooltip-default-standard-shipping.component";
import { SocialAuthOptionsComponent } from "../social-auth-options/social-auth-options.component";
import { NotebookPreviewComponent } from "../shop/productos/product-custom-elements/previews/notebook-preview/notebook-preview.component";
import { TooltipModule } from "@cloudfactorydk/ng2-tooltip-directive";

@NgModule({
  declarations: [
    ItemShopProductComponent,
    ItemPrintProductComponent,
    ItemVendorPrintgroupComponent,
    CartPreviewComponent,
    TooltipDefaultStandardShippingComponent,
    SocialAuthOptionsComponent,
    NotebookPreviewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    HttpClientModule,
    ComponentsModule,
    CouponsModule,
    DesktopHeaderComponentModule,
    FlexLayoutModule,
    QtyStepperModule,
    PromotionDialogModule,
    PopupBeforePayModule,
    GoogleMapsModule,
    RouterModule,
    ProductListModule,
    TooltipModule
  ],
  exports: [
    ItemShopProductComponent,
    ItemVendorPrintgroupComponent,
    CartPreviewComponent,
    TooltipDefaultStandardShippingComponent,
    SocialAuthOptionsComponent,
    ItemPrintProductComponent,
    NotebookPreviewComponent
  ],
  providers: [CurrencyPipe]
})
export class SharedComponentsModule {
}
