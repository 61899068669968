import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import ProductPriceComponent from './product-price.component';



@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [ProductPriceComponent],
  exports: [ProductPriceComponent],
})
export class ProductPriceModule { }
