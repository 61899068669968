import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';

declare const gapi: any;
declare const google: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleDrivePickerService {

  private clientId = environment.google.clientId;
  private apiKey = environment.google.apiKey;
  private appId = environment.firebaseConfig.projectId;
  private scope = 'https://www.googleapis.com/auth/drive';
  // private scope =
  //   'https://www.googleapis.com/auth/drive \
  //   https://www.googleapis.com/auth/drive.file \
  //   https://www.googleapis.com/auth/drive.metadata \
  //   https://www.googleapis.com/auth/drive.metadata.readonly \
  //   https://www.googleapis.com/auth/drive.readonly';

  private tokenClient = null;
  private oauthAccessToken = null;
  private pickerApiLoaded = false;
  private pickerCallback = null;
  private pickerInited = false;
  private gisInited = false;
  private driveLoaded = false;

  public open(callback): void {
    this.pickerCallback = callback;
    gapi.load('client:picker');
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.clientId,
      scope: this.scope,
      immediate: true,

    });
    this.gisInited = true;
    this.onPickerApiLoad();
  }

  onPickerApiLoad() {
    this.pickerInited = true;
    this.createPicker();
  }

  private createPicker(): void {
    const showPicker = () => {
      const view = new google.picker.View(google.picker.ViewId.PDFS);
      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .setAppId(this.appId)
        .setOAuthToken(this.oauthAccessToken)
        .addView(view)
        .addView(new google.picker.DocsUploadView())
        .setDeveloperKey(this.apiKey)
        .setCallback(this.pickerCallback)
        .build();
      picker.setVisible(true);
    };


    // Request an access token
    this.tokenClient.callback = async (response) => {
      if (response.error !== undefined) {
        throw (response);
      }
      this.oauthAccessToken = response.access_token;
      showPicker();
    };
    if (this.oauthAccessToken === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      this.tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      this.tokenClient.requestAccessToken({ prompt: '' });
    }
  }

  async getFiles(docs: any) {
    return new Promise(resolve => {
      gapi.client.load('drive', 'v3', () => {
        const prom = docs.map(doc => {
            return new Promise(resolve => {
              const xhr = new XMLHttpRequest();
              xhr.open('GET', 'https://www.googleapis.com/drive/v3/files/' + doc.id + '?alt=media', true);
              xhr.setRequestHeader('Authorization', 'Bearer ' + this.oauthAccessToken);
              xhr.responseType = 'blob';
              xhr.onload = () => {
                // Get the response Blob.
                const blob = xhr.response;
                resolve(new File([blob], doc.name, { type: doc.mimeType }));
              };
              // Send the XHR request.
              xhr.send();
            });
          }
        );
        Promise.all(prom).then(files => {
          resolve(files);
        });
      });
    });

  }
}
