import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ShoppingCart } from "../../models/cart.model";

@Component({
  selector: 'app-check-laminated-cover',
  templateUrl: './check-laminated-cover.component.html',
  styleUrls: ['./check-laminated-cover.component.scss']
})
export class CheckLaminatedCoverComponent {
  @Input() settings;
  @Input() settingOption;
  @Input() disabled;
  @Input() pFile;
  @Input() isCoverIndeterminated;
  @Input() printProduct: ShoppingCart.OrderItemUi;

  @Output() optionSelect = new EventEmitter<any>();

  //Icono informativo
  infoIcon = {
    name: "warning-outline",
    color: "primary",
    tooltip: "<ol><li>Las medidas de tu documento deberán ser las correctas:(A4=21x29.7cm), (A5=14.8X21cm)</li>" +
      "<br><li>En la encuadernación individual, todos tus documentos tendrán la primera página plastificada a color. Para crear otras configuraciones, puedes crear otro grupo de impresión.</li>" +
      "<br><li>En la encuadernación agrupada, plastificaremos la primera página de tu primer documento (puedes reordenar los documentos a tu gusto)</li>" +
      "<br><li>Todas las portadas plastificadas se imprimen a color.</li></ol>",
    size: "large"
  }

  constructor() {
  }

  ngOnInit() {
  }

  selectOption(event, checkbox) {
    setTimeout(() => {
      if (!this.pFile) {
        const prev = this.settings[this.settingOption].selected;
        this.settings[this.settingOption].selected = checkbox.checked ? 2 : 1;
        this.optionSelect.emit({
          setting: this.settingOption,
          option: this.settings[this.settingOption].selected,
          prev
        });
      } else {
        this.optionSelect.emit({
          action: 'docOptions',
          value: checkbox.checked ? 'coverLaminated' : 'none'
        })
      }
    }, 100)
  }
}
