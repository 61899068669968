import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductListComponent} from './product-list.component';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ProductCardModule} from 'src/app/shared/shop/product-card/product-card.module';


@NgModule({
  declarations: [
    ProductListComponent
  ],
  exports: [
    ProductListComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FlexLayoutModule,

    //
    ProductCardModule,
    // SwiperModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProductListModule {
}
