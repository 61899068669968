import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, AfterViewInit, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-option-turnpage-button',
  templateUrl: './option-turnpage-button.component.html',
  styleUrls: ['./option-turnpage-button.component.scss'],
})

export class OptionTurnpageButtonComponent implements OnInit, AfterViewInit {
  @Input() settings;
  @Output() pageTurnChanged = new EventEmitter<any>();
  @Output() optionSelect = new EventEmitter<any>();
  lotties:any[];

  @ViewChildren('lottie') lottiePlayers: QueryList<any>;

  pageOrientation= 'pageOrientation';
  pageTurn = 'pageTurn';

  selected = 0;
  options = [
    {
      lottie:'/assets/lotties/vertical-largo.json',
      selected: false
    },
    {
      lottie:'/assets/lotties/horizontal-largo.json',
      selected: false
    },    
    {
      lottie:'/assets/lotties/vertical-corto.json',
      selected: false
    },
    {
      lottie:'/assets/lotties/horizontal-corto.json',
      selected: false
    },

  ]
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.lotties = this.lottiePlayers.toArray().map(lp=> lp.nativeElement);
    this.setOptionsState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.settings){
//      console.log("Changes", changes.settings)
      this.selected = this.selectedOption(this.settings['pageOrientation'].selected, this.settings['pageTurn'].selected); 
      this.setOptionsState();
    }    
  }

  onSelectOption(index, event){
    this.selected = index;
    this.pageTurnChanged.emit({
      pageOrientation: {selected: this.selectedSettings('pageOrientation',index), prev: this.settings['pageOrientation'].selected},
      pageTurn: {selected: this.selectedSettings('pageTurn',index), prev: this.settings['pageTurn'].selected}
    })
    this.setOptionsState();
  }

  selectedSettings(setting, option){
    return setting === 'pageTurn'
      ? option < 2 ? 1 : 2
      : option === 0 || option == 2 ? 1 : 2
  }

  selectedOption(orientation, pageturn){
    // Valores van de 1 a 2
    return pageturn == 1
      ?  orientation == 1 
        ? 0
        : 1
      : orientation == 1
        ? 2
        : 3;
  }

  async settingOptionSelect(event){
    this.optionSelect.emit(event);
    this.setOptionsState();
  }

  isSelecetd(index){
    return this.selected === index;
    // return this.settings['autoRotate'].selected === 1 
    //   ? (index < 2 && this.selected < 2) || (index > 1 && this.selected > 1)
    //   : this.selected === index;
  }

  setOptionsState(){
    if (this.lotties){
      this.lotties.forEach(l => l.stop())
      this.options = this.options.map((option, index )=> {
        option.selected = this.isSelecetd(index)
        if (option.selected){
          this.lotties[index].play();
        }
        return option;
      })
    }
  }

}
