// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  min-width: 70px;
  max-height: 70px;
  margin: auto;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtaW5mby1wYWdlLW9yaWVudGF0aW9uLmNvbXBvbmVudC5zY3NzIiwiLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcQ29weWZseSUyMDJcXERlc2t0b3BcXERlc2Fycm9sbG9cXGNvcGlzdGVyaWEtYXBwLWlvbmljXFxzcmNcXGFwcFxcaG9tZS1uZXdcXHRvb2x0aXAtaW5mby1jb21wb25lbnRzXFx0b29sdGlwLWluZm8tcGFnZS1vcmllbnRhdGlvblxcdG9vbHRpcC1pbmZvLXBhZ2Utb3JpZW50YXRpb24uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0VBQ0EsZ0JBQUE7RUFDQSxZQUFBO0FDQ0YiLCJmaWxlIjoidG9vbHRpcC1pbmZvLXBhZ2Utb3JpZW50YXRpb24uY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpbWcge1xyXG4gIG1pbi13aWR0aDogNzBweDtcclxuICBtYXgtaGVpZ2h0OiA3MHB4O1xyXG4gIG1hcmdpbjogYXV0bztcclxufVxyXG4iLCJpbWcge1xuICBtaW4td2lkdGg6IDcwcHg7XG4gIG1heC1oZWlnaHQ6IDcwcHg7XG4gIG1hcmdpbjogYXV0bztcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/home-new/tooltip-info-components/tooltip-info-page-orientation/tooltip-info-page-orientation.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/home-new/tooltip-info-components/tooltip-info-page-orientation/tooltip-info-page-orientation.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;ACCF;ADCA,ozBAAozB","sourcesContent":["img {\r\n  min-width: 70px;\r\n  max-height: 70px;\r\n  margin: auto;\r\n}\r\n","img {\n  min-width: 70px;\n  max-height: 70px;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
