import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DesktopHeaderComponent } from './desktop-header.component';
import { RouterModule } from '@angular/router';
import { MenuPopoverComponentModule } from '../menu-popover/menu-popover.module';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  imports: [ 
    CommonModule, FormsModule, IonicModule,
    RouterModule,
    MenuPopoverComponentModule,
    FlexLayoutModule
  
  ],
  declarations: [DesktopHeaderComponent],
  exports: [DesktopHeaderComponent]
})
export class DesktopHeaderComponentModule {}
