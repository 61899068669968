import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Coupon, UserCoupon} from './coupon.models';
import {environment} from 'src/environments/environment';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {map} from 'rxjs/operators';
import {UserService} from '../core/user.service';
import {Observable} from 'rxjs';
import firebase from '@firebase/app-compat';
const auth = firebase.auth;

@Injectable({
  providedIn: 'root'
})
export class CouponService {
  // coupon: Coupon = {
  //   code: 'XYZ1234',
  //   currency: 'EUR',
  //   title: "",
  //   amount: 12,
  //   type: CouponType.FIXED

  // }  
  constructor(
    private db: AngularFirestore,
    private httpClient: HttpClient,
    private userService: UserService
  ) {
  }

  validate(data): Promise<any> {
    const url = `${environment.apiUrl}coupon/verify`;
    //return couponCode.toUpperCase() === this.coupon.code ? this.coupon : false;
    const { code, email, cartAmount } = data;
    if (auth().currentUser) {
      return auth().currentUser.getIdToken(/* forceRefresh */ false).then(idToken => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${idToken}`,
          'project-id': `${environment.firebaseConfig.projectId}`
        });
        const userId = this.userService.userG.id;
        const body = { code, userId, email, cartAmount }
        // console.log("Body:", body);
        return this.httpClient.request('POST', url, { headers, body }).toPromise();
      })
    } else {
      return Promise.reject(new Error('fail-invalid currentUser'));
    }


    // POST /coupon/verify
    // {
    //   code: string. coupon code introduced by user
    //   userId?: string. user id (only of user is logged in)
    //   email?: string. user email (only if user is not logged in)
    // }
  }

  userCoupon(userID): Promise<Coupon> {
    return this.db.collection('users').doc(userID).collection('usercoupons', ref => ref.limit(1))
      .get()
      .toPromise()
      .then(ref => {
        if (ref.docs[0]) {
          const doc = ref.docs[0];
          const id = doc.id;
          const data = doc.data()
          // console.log("Datos Coupon", {id, ...data})
          return { id, ...data } as Coupon;
        } else {
          return null;
        }

      })
      .catch(err => {
        return null;
      })
  }

  public userCoupons(userID): Promise<UserCoupon[]> {
    const coupons = [];
    return this.db.collection('users').doc(userID).collection('usercoupons')
      .get()
      .toPromise()
      .then(ref => {
        ref.docs.forEach(doc => {
          const id = doc.id;
          let data = doc.data() as UserCoupon

          data.startDate = data.startDate?.toDate();
          data.expirationDate = data.expirationDate?.toDate();
          // console.log(data.startDate, data.expirationDate);

          coupons.push({ id, ...data })
        })
        return coupons
      })
  }

  public getCouponByCode(code): Observable<Coupon> {
    return this.db.collection<Coupon>('coupons', ref =>
      ref.limit(1)
        .where('code', '==', code)
    ).valueChanges().pipe(map((ref) => ref[0]));
  }


  /**
   * Find the first refferred coupon in 'coupon' collection
   *
   */
  public firstReferredCoupon(ReferrerId): Observable<Coupon> {

    return this.db.collection<Coupon>('coupons', ref =>
      ref.limit(1)
        .where('user.id', '==', ReferrerId)
    )
      .valueChanges()
      .pipe(map((ref) => ref[0]))
  }

  // public userCouponsTEST(userID): any{
  //   // const coupons = [];
  //   return this.db.collection('users').doc(userID).collection('usercoupons')
  //   .snapshotChanges()
  //   .pipe( 
  //     map( result => result.map( doc => {
  //       const id = doc.payload.doc.id
  //       const data = doc.payload.doc.data();
  //       // coupons.push({id, ...data})
  //       return {id, ...data}
  //     }))
  //   )

  // }


  //FALTA
  // calcular el monto total tomando en cuenta que sea % o total
  // aplicar el cupon si existe al cambiar usuario u otros casos.
  // el caso es que el usuario puede tener cupon pero es aplicable solo si tiene unos tipos de producto
  // por lo tanto debemos actualizarlo al refrescar el cart


//   applyCoupon(cart, coupon){
// //
//   }
}
