import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
// import { TranslateService } from "@ngx-translate/core";

export class PaginatorIntlService extends MatPaginatorIntl {

  itemsPerPageLabel = 'Items per page';
  nextPageLabel     = 'Next page';
  previousPageLabel = 'Previous page';

  getRangeLabel = function (page, pageSize, length) {

    const of = 'de';

    if (length === 0 || pageSize === 0) {
      return '0 ' + of + ' ' + length;
    }

    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.

    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    
    return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
  };

  injectTranslateService() {

    // this.translateService = translateService;

    // this.translateService.onLangChange.subscribe(() => {
    //   this.translateLabels();
    // });

    this.translateLabels();
  }

  translateLabels() {
    this.itemsPerPageLabel = "Ítems por página";
    this.nextPageLabel = "Siguiente página";
    this.previousPageLabel = "Página anterior";
  }

}