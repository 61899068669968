// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 50px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtaW5mby1zdGFwbGluZy5jb21wb25lbnQuc2NzcyIsIi4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXENvcHlmbHklMjAyXFxEZXNrdG9wXFxEZXNhcnJvbGxvXFxjb3Bpc3RlcmlhLWFwcC1pb25pY1xcc3JjXFxhcHBcXGhvbWUtbmV3XFx0b29sdGlwLWluZm8tY29tcG9uZW50c1xcdG9vbHRpcC1pbmZvLXN0YXBsaW5nXFx0b29sdGlwLWluZm8tc3RhcGxpbmcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0FDQ0YiLCJmaWxlIjoidG9vbHRpcC1pbmZvLXN0YXBsaW5nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW1nIHtcclxuICBtYXgtd2lkdGg6IDUwcHg7XHJcbn1cclxuIiwiaW1nIHtcbiAgbWF4LXdpZHRoOiA1MHB4O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/home-new/tooltip-info-components/tooltip-info-stapling/tooltip-info-stapling.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/home-new/tooltip-info-components/tooltip-info-stapling/tooltip-info-stapling.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;ACCF;ADCA,ooBAAooB","sourcesContent":["img {\r\n  max-width: 50px;\r\n}\r\n","img {\n  max-width: 50px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
