import { Component, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { cloneDeep } from "lodash";
import { Settings } from "../../models/models";

@Component({
  selector: 'app-individual-binding-options-modal',
  templateUrl: './individual-binding-options-modal.component.html',
  styleUrls: ['./individual-binding-options-modal.component.scss'],
})
export class IndividualBindingOptionsModalComponent implements OnInit {

  settings
  printProduct;
  file

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
    const _printSettings = cloneDeep(this.printProduct.product.printingGroup.printSettings);
    this.settings = cloneDeep(this.settings)
    const ringColorOptions = this.settings.ringColor.options;
    const hardCoverFrontOptions = this.settings.hardCoverFront.options;
    const hardCoverBackOptions = this.settings.hardCoverBack.options;
    const laminationTypeOptions = this.settings[Settings.LAMINATION_TYPE].options;
    const laminationWeightOptions = this.settings[Settings.LAMINATION_WEIGHT].options;
    this.settings.ringColor.selected = this.file.ringColor && !_printSettings.grouped ? ringColorOptions.indexOf(ringColorOptions.find(el => el.value.id === this.file.ringColor.id)) + 1 : this.settings.ringColor.selected;
    this.settings.hardCoverFront.selected = this.file.hardCoverFront && !_printSettings.grouped ? hardCoverFrontOptions.indexOf(hardCoverFrontOptions.find(el => el.value.id === this.file.hardCoverFront.id)) + 1 : this.settings.hardCoverFront.selected;
    this.settings.hardCoverBack.selected = this.file.hardCoverBack && !_printSettings.grouped ? hardCoverBackOptions.indexOf(hardCoverBackOptions.find(el => el.value.id === this.file.hardCoverBack.id)) + 1 : this.settings.hardCoverBack.selected;
    this.settings[Settings.LAMINATION_TYPE].selected = laminationTypeOptions.indexOf(laminationTypeOptions.find(el => el.value === this.file.laminationType)) + 1;
    this.settings[Settings.LAMINATION_WEIGHT].selected = laminationWeightOptions.indexOf(laminationWeightOptions.find(el => el.value === this.file.laminationWeight)) + 1;
  }

  cancel() {
    this.modalController.dismiss()
  }

  submit() {
    this.modalController.dismiss({ settings: this.settings })
  }

  optionSelect(event) {
    if (![Settings.LAMINATION_WEIGHT, Settings.LAMINATION_TYPE, 'ringColor', 'hardCoverFront', 'hardCoverBack'].includes(event.setting)) {
      return
    }
    this.settings[event.setting].selected = event.option;
  }
}
