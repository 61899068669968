import { Component, Input, OnInit } from '@angular/core';
import { PiggyTransactions } from "../../models/piggy.model";
import { Product } from "../../models/product.model";
import { OrderTypeStatus, PiggyTransactionType } from "../../models/models";

@Component({
  selector: 'app-ambassador-products-table',
  templateUrl: './ambassador-products-table.component.html',
  styleUrls: ['./ambassador-products-table.component.scss'],
})
export class AmbassadorProductsTableComponent implements OnInit {

  @Input() ambassadorProducts: Array<Product>;
  @Input() userId;
  constructor() { }

  ngOnInit() {}

  protected readonly PiggyTransactionType = PiggyTransactionType;
  protected readonly OrderTypeStatus = OrderTypeStatus;
}
