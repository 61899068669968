import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { BehaviorSubject, lastValueFrom, Observable, of, pluck } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { PickupLocation } from '../models/pickup-location.model';
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { PickupPoint } from "../models/pickup-point.model";

@Injectable({
  providedIn: 'root'
})
export class PickupLocationService {
  private collection: AngularFirestoreCollection<PickupLocation>;
  maxDistanceToPoint = 3;
  daysToUpdatePoints = 5;
  //PICKUP POINTS
  API_GET_MRW_POINTS = 'api/shipping/getMRWPoints';
  API_UPDATE_POINTS = 'api/shipping/updatePoints';
  availableCouriers: {} = [];
  scheduleByCourier: {} = [];
  private shippingSettingsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  shippingSettings$: Observable<any> = this.shippingSettingsSubject.asObservable();

  constructor(
    private db: AngularFirestore,
    private functions: AngularFireFunctions
  ) {
    this.collection = this.db.collection<PickupLocation>('pickupLocations');

    this.db.collection('settings').doc('shippingSettings').valueChanges()
      .pipe(
        map((data: any) => ({
          availableCouriers: data?.availableCouriersForPoints,
          scheduleByCourier: data?.scheduleByCourier
        })),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
      .subscribe((settings: any) => {
        const { availableCouriers, scheduleByCourier } = settings;
        if (availableCouriers) {
          this.availableCouriers = Object.keys(availableCouriers)
            .filter(key => availableCouriers[key].enabled)
            .reduce((result, key) => {
              result[key] = availableCouriers[key];
              return result;
            }, {});
          this.shippingSettingsSubject.next(true);
        }

        if (scheduleByCourier) {
          this.scheduleByCourier = scheduleByCourier;
          this.shippingSettingsSubject.next(true);
        }
      });

  }

  getByProvinceId(provinceId: string, limit: number = 20): Observable<PickupLocation[]> {
    return (provinceId?.length > 1)
      ? this.db.collection('pickupLocations', ref =>
        ref.limit(limit)
          .where('provinceId', '==', provinceId)
          .where('enabled', '==', true)
      )
        .valueChanges({ idField: 'id' })
        .pipe(
          tap((a) => {
            // console.log("--->llegan Datos", a)
          }),
          map(data => data as PickupLocation[])
        )
      : of([])
  }

  async getNearestPointsToCoordinates(coordinates) {
    const coordRanges = this.calculateMaxMinCoordinates(coordinates.lat, coordinates.lng)

    if (!Object.keys(this.availableCouriers).length) {
      return [];
    }
    const pointsSnap = await lastValueFrom(this.db.collection('pickupPoints', ref =>
      ref.where('latitude', '<=', coordRanges.latMax)
        .where('latitude', '>=', coordRanges.latMin)
        .where('longitude', '<=', coordRanges.lngMax)
        .where('longitude', '>=', coordRanges.lngMin)
        .where('company', 'in', Object.keys(this.availableCouriers))
    ).get())
    return pointsSnap.docs.map(doc => doc.data()).filter((point: PickupPoint) => this.isCompanyOperating(point.company, new Date()));
  }

  calculateMaxMinCoordinates(lat: number, lng: number): {
    latMin: number,
    latMax: number,
    lngMin: number,
    lngMax: number
  } {
    // Earth's radius in kilometers
    const earthRadius = 6371;

    // Convert distance to degrees
    const distanceRadians = this.maxDistanceToPoint / earthRadius;

    // Convert latitude and longitude to radians
    const latRad = lat * (Math.PI / 180);
    const lngRad = lng * (Math.PI / 180);

    // Calculate lat range
    const latMax = lat + (distanceRadians * (180 / Math.PI));
    const latMin = lat - (distanceRadians * (180 / Math.PI));

    // Calculate lng range
    const deltaLng = Math.atan2(Math.sin(distanceRadians) * Math.cos(latRad), Math.cos(distanceRadians) - Math.sin(latRad) * Math.sin(latRad));
    const lngMax = lng + (deltaLng * (180 / Math.PI));
    const lngMin = lng - (deltaLng * (180 / Math.PI));

    return {
      latMin,
      latMax,
      lngMin,
      lngMax
    };
  }

  async checkIfUpdatePoints(points) {
    const postalCodesToUpdate = [];
    const currentDate = new Date();
    for (let p of points) {
      const diffMillis = currentDate.getTime() - new Date(p.updatedAt.seconds * 1000 + p.updatedAt.nanoseconds / 1000000).getTime();
      const diffDays = Math.ceil(diffMillis / 86400000);
      if (!postalCodesToUpdate.includes(p.postalCode) && diffDays > this.daysToUpdatePoints) {
        postalCodesToUpdate.push(p.postalCode)
      }
    }
    if (postalCodesToUpdate.length) {
      await this.updatePoints(postalCodesToUpdate)
      return true
    } else {
      return false
    }
  }

  private async updatePoints(postalCodesToUpdate: any[]) {
    return this.functions.httpsCallable(this.API_UPDATE_POINTS)(postalCodesToUpdate).toPromise();
  }


  _API_get_MRW_points(data = null) {
    return this.functions.httpsCallable(this.API_GET_MRW_POINTS)(data).toPromise();
  }

  getPointByCode(pointService = 'mrw', pickupPointCode = null) {

    return new Promise<any>((resolve, reject) => {
      if (!Object.keys(this.availableCouriers).includes(pointService)
        || !this.isCompanyOperating(pointService, new Date())) {
        return resolve({ point: null })
      }
      this.db.collection('pickupPoints', ref =>
        ref.where('id', '==', pickupPointCode)
          .where('company', '==', pointService).limit(1)
      ).get().toPromise().then(pointDBSnap => {
        const pointDB = pointDBSnap.docs[0]?.data()
        return resolve({ point: pointDB ?? null });
      });
    });
  }

  isWithinSchedule(schedule, currentTime) {
    const [fromHours, fromMinutes] = schedule.from.split(':').map(Number);
    const [toHours, toMinutes] = schedule.to.split(':').map(Number);

    const fromTime = new Date(currentTime);
    fromTime.setHours(fromHours, fromMinutes, 0, 0);

    const toTime = new Date(currentTime);
    toTime.setHours(toHours, toMinutes, 0, 0);

    return fromTime <= currentTime && currentTime <= toTime;
  }

  isCompanyOperating(company, currentTime) {
    const companySchedules = this.scheduleByCourier[company];
    if (!companySchedules) return true; // Operates 24/7 if no schedules defined

    const allDisabled = companySchedules.every(schedule => !schedule.enabled);
    if (allDisabled) return true; // Operates 24/7 if all schedules are disabled

    return companySchedules.some(schedule => schedule.enabled && this.isWithinSchedule(schedule, currentTime));
  }
}
