import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import ProductCardComponent from './product-card.component';
import { IonicModule } from '@ionic/angular';
import { ProductCardInfoModule } from './product-card-info/product-card-info.module';
import { ProductPriceModule } from './product-price/product-price.module';
import { ProductLabelModule } from './product-label/product-label.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { TooltipModule, TooltipOptions } from '@cloudfactorydk/ng2-tooltip-directive';
import { DefaultTooltipOptions } from 'src/app/home/default-tooltip-options';
import { AddToCartStepsComponent } from './add-to-cart-steps/add-to-cart-steps.component';
import { ProductVariantsModule } from "../../../shop/productos/product-variants/product-variants.module";
import { SharedComponentsModule } from 'src/app/cart/shared-components.module';
import { ProductPartInfoInteractionComponentModule } from 'src/app/shop/productos/product-part-info-interaction/product-part-info-interaction.module';
import { QtyStepperModule } from '../qty-stepper/qty-stepper.module';

@NgModule({
  declarations: [
    ProductCardComponent,
    ProductCardComponent,
    AddToCartStepsComponent
  ],
  exports: [
    ProductCardComponent,
    ProductCardComponent,
    AddToCartStepsComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FlexLayoutModule,
    ProductCardInfoModule,
    ProductPriceModule,
    ProductLabelModule,
    TooltipModule.forRoot(DefaultTooltipOptions as TooltipOptions),
    ProductVariantsModule,
    ProductPartInfoInteractionComponentModule,
    QtyStepperModule
    //SharedComponentsModule
    //AddCartPopupComponentModule
]
})
export class ProductCardModule {
}
