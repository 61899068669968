import { Component, OnInit, TemplateRef, Type, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';


export type ConfirmDialogType = 'default' | 'delay' | 'limit' | 'canary';

enum ConfirmDialogTemplate {
  DEFAULT = 'Default',
  DELAY = 'Delay',
  LIMIT = 'Limit',
  CANARY = 'Canary'
}
export interface DialogRef{
  type: ConfirmDialogType,
  template: ConfirmDialogTemplate
}

@Component({
  selector: 'app-confirm-popover',
  templateUrl: './confirm-popover.component.html',
  styleUrls: ['./confirm-popover.component.scss'],
})
export class ConfirmPopoverComponent{
  @ViewChild(ConfirmDialogTemplate.DEFAULT) [ConfirmDialogTemplate.DEFAULT]: TemplateRef<any>;
  @ViewChild(ConfirmDialogTemplate.DELAY) [ConfirmDialogTemplate.DELAY]: TemplateRef<any>;
  @ViewChild(ConfirmDialogTemplate.LIMIT) [ConfirmDialogTemplate.LIMIT]: TemplateRef<any>;
  @ViewChild(ConfirmDialogTemplate.CANARY) [ConfirmDialogTemplate.CANARY]: TemplateRef<any>;

  typeId:number=0;
  set type(type:ConfirmDialogType){
    this.typeId = this.modalData.findIndex(c => c.type == type) ?? 0;
    // console.log("type:",type);
  }

  TempC = ConfirmDialogTemplate;

  modalData: Array<DialogRef> = [
    { 
      type:'default',
      template: ConfirmDialogTemplate.DELAY
    },
    {
      type:'delay',
      template: ConfirmDialogTemplate.DELAY
    },
    {
      type:'limit',
      template: ConfirmDialogTemplate.LIMIT
    },
    {
      type:'canary',
      template: ConfirmDialogTemplate.CANARY
    },
  ]
  constructor(
    private popover: PopoverController
  ) { }

  onAccept(){
    this.popover.dismiss(true)
  }
  onCancel(){
    this.popover.dismiss(false)
  }
}