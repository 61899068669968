import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
  styleUrls: ['./circular-progress-bar.component.scss'],
})
export class CircularProgressBarComponent implements OnInit, AfterViewInit {

  @Input() progress = 40;
  @Input() progressWidth = 20;
  @Input() animatedProgress = true;
  @Input() animatedImage = false;
  @Input() completedColor = 'black';
  @Input() remainingColor = '#f2f2f4';
  @Input() imageSrc = null;
  @ViewChild('roundShape', { static: false }) roundShape: ElementRef;
  currentProgress = 0;

  constructor() {
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.roundShape.nativeElement.style.setProperty('--completed-color', this.completedColor);

    if (!this.animatedProgress) {
      this.currentProgress = this.progress;
    }
    setTimeout(() => {
      setInterval(() => {
        if (this.currentProgress < this.progress)
          this.currentProgress += 1;
      }, 10);
    }, 300);
  }
}
