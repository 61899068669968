import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { MenuController, ModalController, ToastController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/core/user.service';
import {
  DiscountType,
  Finishing,
  OrderPaymentStatus,
  OrderProcessStatus,
  OrderShippingStatus,
  PaymentType,
  ProductType,
  Settings
} from 'src/app/models/models';
import { MRWPoint } from 'src/app/models/mrw-point.model';
import { Setting } from 'src/app/models/setting.model';
import { ShippingType } from 'src/app/models/shipping-settings.model';
import { AppService } from 'src/app/shared/app.service';
import { CartService } from 'src/app/shared/cart.service';
import { OrderStateService } from 'src/app/shared/order-state.service';
import { OrderService } from 'src/app/shared/order.service';
import { PickupLocationService } from 'src/app/shared/pickup-location.service';
import { ShippingService } from 'src/app/shared/services/shipping.service';
import { SwiperOptions } from 'swiper/types';
import { ProvinceModel } from '../address-container/province/province.component';
import { CouponService } from 'src/app/coupons/coupon.service';
import { Coupon } from 'src/app/coupons/coupon.models';
import { environment } from 'src/environments/environment';
import EventEmitter from 'events';
import { ConfirmModalCartComponent } from 'src/app/components/confirm-modal-cart/confirm-modal-cart.component';
import { WalletService } from 'src/app/wallet/wallet.service';
import { Product } from "../../models/product.model";
import _Product = Product._Product;

export const ShippingHintTextOptions = {
  [ShippingType.ECONOMIC]:
    "Recibe en 1-3 días si pides antes de las 2 de la tarde entre Lunes y Jueves.",
  [ShippingType.STANDARD]:
    "Recibe en 1 o 2 días hábiles si pides antes de las 2 de la tarde entre Lunes y Jueves.",
  [ShippingType.STANDARD_24]:
    "Recibe al día siguiente si pides antes de las 2 de la tarde entre Lunes y Jueves.",
  [ShippingType.URGENT]:
    "Recibe a la mañana siguiente si pides antes de las 2 de la tarde entre Lunes y Jueves.",
  [ShippingType.PICKUP]: "",
};


@Component({
  selector: 'app-cart-preview',
  templateUrl: './cart-preview.component.html',
  styleUrls: ['./cart-preview.component.scss'],
})
export class CartPreviewComponent implements OnInit {

  shippingTypeLabels = {
    [ShippingType.ECONOMIC]: "Envío económico",
    [ShippingType.STANDARD]: "Envío estándar",
    [ShippingType.STANDARD_24]: "Envío prioritario",
    [ShippingType.URGENT]: "Envío urgente",
  };


  private eventEmitter: EventEmitter;
  @Input() payingProcess: boolean = false;

  productType = ProductType;
  discountType = DiscountType;
  freeShipping: boolean = false;
  _paymentType = PaymentType;
  shippingAddress: UntypedFormGroup;
  billingAddress: UntypedFormGroup;
  shippingType = ShippingType;

  productsSectionHidden = false;
  productSwiperConfig: SwiperOptions = {
    autoHeight: false,
    slidesPerView: 1,
    spaceBetween: 40,
    mousewheel: {
      releaseOnEdges: true,
    },
    //pagination: { clickable: true },
    // scrollbar: { draggable: true, enabled: false },
    keyboard: true,
    zoom: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: true
    },
    centeredSlides: true,
    //centerInsufficientSlides: true,
    breakpoints: {
      // when window width is >= 320px
      300: {
        slidesPerView: 1.5,
        spaceBetween: 15,
        centeredSlides: true
      },
      1025: {
        slidesPerView: 2,
      }
    }
  };
  isLoading: boolean = false;
  order = null;
  orderFormGroup: UntypedFormGroup;
  private componetDestroyed = new Subject();
  private ["settings"]: Setting.General;
  pointSelected: MRWPoint = null;

  shippingHintText = "";
  shippintFreeLimit = 0;
  allProvincesStrings;
  storeProductsInCart: _Product[];

  constructor(
    private menuCtrl: MenuController,
    public cartService: CartService,
    private router: Router,
    private orderStateService: OrderStateService,
    private appS: AppService,
    public userService: UserService,
    private pickupService: PickupLocationService,
    public shippingService: ShippingService,
    private route: ActivatedRoute,
    private orderService: OrderService,
    public toastController: ToastController,
    private http: HttpClient,
    private couponService: CouponService,
    private fb: UntypedFormBuilder,
    private modalController: ModalController,
    public walletService: WalletService
  ) {
    this.orderFormGroup = this.newOrderForm();
    this.eventEmitter = new EventEmitter();

    /*this.eventEmitter.on('freeShippingChanged', (newFreeShippingValue) => {
      if (newFreeShippingValue && this.cartService.coupon) {
        this.openConfirmModal();
      }
    });*/
  }

  ngOnInit() {
    //this.cartService.walletDeselected = true;
    this.cartService.normalizeFiles();
    this.storeProductsInCart = this.cartService.getStoreProductsInCart().map(item => item.product);

    this.appS.generalSettings$
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((settings: Setting.General) => (this.settings = settings));

    // suscripción a Orden
    this.orderStateService.order$
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((order) => {
        this.order = order;
        if (order) {

          /*const hasWallet = this.walletService.wallet.balance !== 0;
          let hasWalletBonus;
          if (order.cart) {
            hasWalletBonus = order.cart.items.find(item => item.product.type === ProductType.WALLETBONUS);
          }
          this.cartService.discountWallet = hasWalletBonus || hasWallet ? true : false;*/

          // console.log("@new Order", order)
          //this.cartService.postalCode = order.shippingAddress.postalCode;
          //this.cartService.order = order
          // Al cambiar el tipo de envío recalculamos el precio
          /*if (this.cartService.shippingType != order.shippingType) {
            this.cartService.shippingType = order.shippingType;
            this.cartService.refreshCartPrice();
          }*/
          /*if (order.email) {
            this.userService.getUserByEmail(order.email).then((user) => {
              if (user.gender && !this.orderFormGroup.controls.gender.value) {
                this.orderFormGroup.controls.gender.setValue(user.gender);
              }
              if (
                user.ocupation &&
                !this.orderFormGroup.controls.ocupation.value
              ) {
                this.orderFormGroup.controls.ocupation.setValue(user.ocupation);
              }
            });
          }*/

          /*this.orderFormGroup.patchValue(order, { emitEvent: false });
          const pointCode = this.orderFormGroup
            .get("shippingAddress")
            .get("pickupPointCode").value;
          const pickupService = this.orderFormGroup
            .get("shippingAddress")
            .get("shippingCourier").value;
          if (pointCode) {
            this.pickupService
              .getPointByCode(pickupService, pointCode)
              .then(({ point }) => {
                if (!point) {
                  this.clearPickupPoint();
                } else {
                  this.pointSelected = point;
                }
              });
          }*/
          // ********************** */
          // Shiipping Options
          // ********************* */
          /*this.shippingHintText = ShippingHintTextOptions[order.shippingType];
          if (["07", "35", "38"].includes(order.shippingAddress.provinceId)) {
            this.shippingHintText = this.shippingHintText.replace(
              "1 o 2",
              "2 o 3"
            );
          }*/
          this.shippintFreeLimit =
            order.shippingType === ShippingType.STANDARD_24
              ? this.shippingService.shippingSettings.extra_24
              : order.shippingType === ShippingType.URGENT
                ? this.shippingService.shippingSettings.extra_urgent
                : 0;
          /* Fin Shipping Section **/
        }
      });

    // Suscripción a cambios en formulario
    /*this.orderFormGroup.valueChanges
      .pipe(takeUntil(this.componetDestroyed))
      .subscribe((properties) => {
        // console.log("properties", properties)
        const data = {} as any;
        for (const property in properties) {
          const control = this.orderFormGroup.controls[property];
          if (control.valid && control.dirty) {
            data[property] = properties[property];
            control.markAsPristine();
          }
        }
        if (Object.keys(data).length > 0) {
          this.orderStateService.updateProperty(data);
        }
      });*/
    this.route.queryParams.subscribe(async (params) => {
      this.orderStateService.subscribeUser(false);
      const orderId = params.orderId;
      /*if (orderId) {
        this.isLoading = true;
        const _order = await this.orderService.get(orderId);
        // console.log('_order', _order);
        if (_order) {
          _order.id = orderId;
          // console.log('->>>loadSavedOrder');
          await this.orderStateService.loadSavedOrder(_order);
        } else {
          // TODO mostrar un mensaje
          // Mensaje de que no existe la orden
          const toast = await this.toastController.create({
            message: "La orden no existe.",
            color: "warning",
            position: "middle",
            duration: 1200,
          });
          toast.present();
        }
        this.router.navigate(["/cart"]);
        this.isLoading = false;
      }*/
      this.orderStateService.subscribeUser(true);
      this.cartService.refreshCartPrice();
    });
    //this.cartService.cleanCart();
    //this.getProvincesStrings();

    //this.onChanges();
  }


  closePreview() {
    this.menuCtrl.close('cart-preview');
    if (this.cartService.currentStep === 1 && !this.cartService.cart?.items.length) {
      this.router.navigate(['/']);
    }
  }

  goToCart() {
    this.cartService.goToCart();
    this.closePreview();
  }

  getProgressBarValue() {
    const basePrice = this.getBasePrice() + this.cartService.getDiscount(DiscountType.COUPON);
    //this.checkFreeShipping(basePrice >= 35);
    //  El precio para el ENVÍO GRATUITO depende del código postal,
    return basePrice / this.cartService.getFreeShippingAmount(this.cartService.order?.shippingAddress?.postalCode);
  }

  /*checkFreeShipping(value) {
    if (this.freeShipping !== value) {
      this.freeShipping = value;
      this.eventEmitter.emit('freeShippingChanged', value);
    }
  }*/

  getBasePrice() {
    return (
      this.cartService.cart.price.totalAmt -
      this.cartService.getWalletDiscount() -
      this.cartService.cart.shipping.price.amt -
      (this.cartService.coupon
        ? this.cartService.getDiscount(this.discountType.COUPON)
        : 0)
    );
  }

  getRemainingAmount() {
    return (this.cartService.getFreeShippingAmount(this.cartService.order?.shippingAddress?.postalCode) - this.cartService.shippingProductPrice
      - this.cartService.getDiscount(DiscountType.COUPON)).toFixed(2);
  }

  // TODO cambiar la forma de filtrar los printgroup, mejor con pipe o suscripcion
  printGroupItems(items) {
    return items.filter(
      (item) => item.product.type === ProductType.PRINT && !item.product.vendor
    );
  }

  getFolderName(item, i) {
    return item.product.folderName ?? `Grupo #${i + 1}`
  }

  removePrintProduct(item) {
    const it = this.cartService.cart.items.findIndex((_item) => item === _item);
    this.cartService.cart.items.splice(it, 1);
    this.cartService.refreshCartPrice();
  }

  editPrintProduct(item) {
    const navigationExtras: NavigationExtras = { state: { item } };
    this.router.navigate(["/"], navigationExtras);
  }

  exceededBindingItems(printingGroup) {
    const elementsExceeded = [];
    if (printingGroup.printSettings[Settings.FINISHING] !== Finishing.BINDING) {
      return elementsExceeded;
    }
    const covers = printingGroup.binding.covers.filter((cov1) => {
      return this.cartService.bindingStockExceeded.covers.find(
        (cov2) => cov1.color === cov2.color && cov1.pageSize === cov2.pageSize
      );
    });

    const rings = printingGroup.binding.rings.filter((cov1) => {
      return this.cartService.bindingStockExceeded.rings.find(
        (cov2) => cov1.color === cov2.color && cov1.diameter == cov2.diameter
      );
    });

    covers.forEach((cov) => {
      elementsExceeded.push(`Tapa ${cov.name.toLowerCase()}`);
    });

    rings.forEach((ring) => {
      elementsExceeded.push(`Anilla ${ring.name.toLowerCase()}`);
    });

    return elementsExceeded;
  }

  getPrintProductDescription(item) {
    const sides = item.product.printingGroup.printSides;
    let ret = "";
    ret += `${sides} ${sides == 1 ? " página impresa" : " páginas impresas"}`;
    const finishing = item.product.printingGroup.printSettings.finishing;
    if (finishing == Finishing.BINDING || finishing == Finishing.BINDINGX) {
      ret += ` y ${item.product.extra.units} `;
      ret += `${
        item.product.extra.units == 1 ? "encuadernación" : "encuadernaciones"
      }`;
    }
    ret += " por duplicado";
    return ret;
  }

  /*
   * Check  if exist the product
   */
  productExist(items) {
    //TODO J.A. verificar que esté bien se cambió por un find
    let exist = false;
    // if (items) {
    //   items.forEach(item => {
    //     if (item.product.type === this.productType.STORE || item.product.type === this.productType.WALLETBONUS || item.product.type === this.productType.GENERIC ||
    //       (item.product.type === this.productType.PRINT && item.product.vendor)
    //     ) {
    //       exist = true;
    //       return exist;
    //     }
    //   });
    // }
    // return exist;
    return !!items?.find(
      (item) =>
        item.product.type === this.productType.STORE ||
        item.product.type === this.productType.WALLETBONUS ||
        item.product.type === this.productType.GENERIC ||
        (item.product.type === this.productType.PRINT /*&& item.product.vendor*/)
    );
  }

  existStationeryProducts(items) {
    return !!items?.find(
      (item) =>
        item.product.type === this.productType.STORE ||
        item.product.type === this.productType.WALLETBONUS ||
        item.product.type === this.productType.GENERIC
    );
  }

  newPrintProduct() {
    this.router.navigate(["/"]);
    this.closePreview();
  }


  async onSaveDraft() {
    if (!this.isLoading) {
      if (this.order.userId) {
        this.isLoading = true;
        this.orderStateService
          .saveDraft()
          .then((_) => {
            this.orderStateService.resetOrder();
            // TODO mostrar mensajito
            this.router.navigate(["/"]);
          })
          .catch((err) => {
            console.log("Error", err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        const returnPath = "/cart";
        this.router.navigate(["/auth/login"], { queryParams: { returnPath } });
      }
    }
  }


  // Aplicamos el cupon al carrito
  applyCoupon(coupon) {
    // console.log("Cupona  a aplicar: ",{coupon});
    this.cartService.coupon = coupon;
    this.cartService.refreshCartPrice();
  }

  /*private clearPickupPoint() {
    const tempAddress = this.orderFormGroup.get("shippingAddress");
    tempAddress.get("city").setValue("");
    tempAddress.get("addressLine1").setValue("");
    tempAddress.get("addressLine2").setValue("");
    tempAddress.get("pickupPointName").setValue("");
    tempAddress.get("pickupPointCode").setValue("");
    tempAddress.get("shippingCourier").setValue("");
    this.orderStateService.updateProperty({
      shippingAddress: tempAddress.value,
      shippingCourierService: null
    });
    this.pointSelected = null;
  }*/

  getProvincesStrings() {
    this.http
      .get<any>("./assets/data/provincias.json")
      .toPromise()
      .then((data: ProvinceModel[]) => {
        this.allProvincesStrings = data;
      });
  }

  onChanges(): void {
    // TODO CHECK vambio de email porel mismo manejo de usuario
    // Debemos colocarlo en el escuchadore genérico
    // //observa cambios en Email
    this.orderFormGroup
      .get("email")
      .valueChanges.pipe(takeUntil(this.componetDestroyed))
      .subscribe((val) => {
        if (val && this.orderFormGroup.get("email").valid) {
          if (!this.orderFormGroup.get("userId").value) {
            this.validateCoupon(null);
            this.cartService.userCoupon = null;
          }
        }
      });

    // TODO CHECK
    // Dejamos por ahora, parece estar funcionando bien
    // Se observa si se debe aplicar el cupon de user
    this.cartService.applyUserCoupon$
      .pipe(shareReplay(1), takeUntil(this.componetDestroyed))
      .subscribe((coupon) => {
        // console.log("CHECK:",coupon);
        setTimeout(() => {
          this.validateCoupon(this.cartService.userCoupon);
        }, 100);
      });
  }

  validateCoupon(coupon) {
    const code = coupon ? coupon.code : null;

    // console.log("validando cupon", {coupon});

    // Si hay variación en el cupon veriicamos si hay que aplicarlo
    if (coupon !== this.cartService.coupon) {
      if (this.cartService.canUseCoupon && code) {
        const email = this.orderFormGroup.controls.email.value;
        this.couponService
          .validate({
            code: code.toUpperCase(),
            email,
            cartAmount: Number(
              this.cartService.cart.price.totalAmt -
              this.cartService.cart.shipping.price.amt
            ).toFixed(2),
          })
          .then((resp: Coupon) => {
            // resp:Coupon
            if (!resp.valid) {
              resp = null;
            }
            this.applyCoupon(resp);
          });
      } else {
        this.applyCoupon(null);
      }
    }
  }

  async openConfirmModal() {
    const modal = await this.modalController.create({
      component: ConfirmModalCartComponent,
      cssClass: "auto-height coupon-warning",
      backdropDismiss: false,
      componentProps: {
        warningCoupon: true
      },
    });
    modal.present();
  }

  getShippingLabel() {
    return this.shippingTypeLabels[this.order.shippingType];
  }

  newOrderForm() {
    this.billingAddress = this.addressForm();
    this.billingAddress.controls.recipient.clearValidators();
    this.billingAddress.controls.phone.clearValidators();
    this.billingAddress.updateValueAndValidity();

    this.shippingAddress = this.addressForm();
    return this.fb.group(
      {
        _version: 1,
        _appVersion: environment.version,
        id: null,
        userId: null,
        number: [""],
        formStepShipping: this.fb.group({
          name: ["", [Validators.required, Validators.pattern(/^.{2,}\ .{2,}/)]],
          email: ['', [Validators.email, Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,10}$')]],
          phone: [
            "",
            [
              Validators.required,
              Validators.minLength(9),
              Validators.maxLength(11),
            ],
          ],
          shippingType: this.shippingService.isShippingTypeAvailable(ShippingType.ECONOMIC, this.shippingAddress.get('postalCode').value) ? ShippingType.ECONOMIC : ShippingType.STANDARD,
          pickupAddress: null,
          shippingAddress: this.shippingAddress,
          billingAddress: this.billingAddress,
          shippingCourierService: null,
        }),

        cart: [{}],
        paymentStatus: [OrderPaymentStatus.PENDING],
        processStatus: [OrderProcessStatus.PENDING],
        shippingStatus: [OrderShippingStatus.PENDING],
        paymentType: [
          { value: this._paymentType.CREDITCARD },
          { updateOn: "change" },
          Validators.required
        ],
        paymentService: null,
        policy: [false, Validators.requiredTrue],
        ads: [null],
        printProtected: [null],
        ocupation: [null, [Validators.required]],
        gender: [null, [Validators.required]],
      },
      //{ updateOn: "blur" } Las validaciones se realizan en cada cambio de valor quitando esta opción
    );
  }

  addressForm(_address?) {
    const postalVal = [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
      Validators.pattern(/^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/),
    ];
    const address = this.fb.group({
      recipient: [
        "",
        [Validators.required, Validators.pattern(/^.{2,}\ .{2,}/)],
      ],
      addressLine1: ["", Validators.required],
      addressLine2: [""],
      city: ["", Validators.required],
      province: ["", Validators.required],
      provinceId: [""],
      postalCode: [
        "",
        { validators: Validators.compose(postalVal), updateOn: "change" },
      ],
      country: [""],
      phone: [
        "",
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(11),
        ],
      ],
      organization: [""],
      pickupPointName: [""],
      pickupPointCode: [""],
      shippingCourier: [""],
      taxId: [""],
      dni: ["", { updateOn: "change" }],
    });
    return address;
  }


  keepMinZero(value: number) {
    return Math.max(0, value)
  }

  getAfterPrice() {
    let afterPrice = this.getBasePrice() + this.cartService.getDiscount(this.discountType.COUPON)

    if (this.cartService.coupon && this.cartService.coupon?.freeShipping) {
      const { amt } = this.cartService.getShippingPrice(this.cartService.postalCode, this.cartService.cart, ShippingType.STANDARD);
      afterPrice += amt
    }
    return afterPrice
  }

  closeProductsSection() {
    this.productsSectionHidden = true;
  }

  cartHasBonusWallet() {
    return this.cartService.cart.items.some(item =>
      item.product.type === this.productType.WALLETBONUS);
  }

  isOnlyFreeShipping() {
    if (!this.cartService.coupon) {
      return false
    }

    const shippingAmt = this.cartService.getShippingPrice(this.cartService.postalCode, this.cartService.cart, ShippingType.STANDARD).amt;
    return this.cartService.coupon?.freeShipping && shippingAmt + this.cartService.getDiscount(this.discountType.COUPON) === 0
  }
}
