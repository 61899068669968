import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, mergeMap, shareReplay } from 'rxjs/operators';
import { serverTimestamp } from '@angular/fire/firestore';
import { Product, ProductType } from 'src/app/models/product.model';
import { toDate } from '../utils/utils';
import { ElasticSearchService } from 'src/app/core/elastic-search.service';
import { AppService } from '../app.service';
import { MediaFile } from '../../models/media.model';
import { Category } from '../../models/category.model';
import _Product = Product._Product;

@Injectable({
  providedIn: 'root'
})
export class ProductDbService {

  private collection: AngularFirestoreCollection<Product>;

  constructor(
    private db: AngularFirestore,
    private es: ElasticSearchService,
    private appService: AppService
  ) {
    this.collection = this.db.collection<Product>('products');
  }

  get$(id: string): Observable<Product | undefined> {
    return this.collection.doc<Product>(id).valueChanges({ idField: 'id' })
      .pipe(map(data => data ? this.normalize(data) : undefined));
  }

  upsert(_data): Promise<any> {
    // console.log("Data", _data);
    const { id, ...data } = _data;
    if (!id) {
      data.createdAt = serverTimestamp();
    } else {
      delete data.soldQty;
      delete data.stock;
    }
    data.updatedAt = serverTimestamp();

    return id
      ? this.collection.doc(id).update(data)
      : this.collection.add(data);
  }

  set(_data): Promise<any> {
    const { id, ...data } = _data;
    data.createdAt = serverTimestamp();
    data.updatedAt = serverTimestamp();
    return this.collection.doc(id).set(data);
  }

  delete(id: string) {
    return this.collection.doc<Product>(id).delete();
  }

  getAll$(): Observable<Product[]> {
    return this.collection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = this.normalize(a.payload.doc.data());
        const id = a.payload.doc.id;
        return { id, ...data } as Product;
      }))
    );
  }

  getBySlug$(slug): Observable<Product> {
    return this.db.collection<Product>('products', ref => ref
      .where('slug', '==', slug)
      .limit(1))
      .valueChanges({ idField: 'id' })
      .pipe(map(arr => this.normalize(arr[0])));
  }

  /**
   *
   * @param options {type:'documents'|'product', query:{featured:true}}
   * @returns
   */
  getByQuery(options): Observable<Product[]> {
    let { type, query, limit } = options;
    query = query ?? {};
    let dbQuery = this.db.collection<Product>('products').ref
      .limit(limit ?? 6);
    if (type === 'product') {
      dbQuery = dbQuery.where('type', '==', ProductType.STORE);
    }

    if (type === 'document') {
      dbQuery = dbQuery.where('type', '==', ProductType.PRINT);
      dbQuery = dbQuery.where('vendor', '!=', false);
    }

    if ('featured' in query) {
      dbQuery = dbQuery.where('featured', '==', query.featured);
    }
    if ('status' in query) {
      dbQuery = dbQuery.where('status', '==', query.status);
    }
    if ('vendor' in query) {
      dbQuery = dbQuery.where('vendor.id', '==', query.vendor);
    }
    if ('internalId' in query) {
      dbQuery = dbQuery.where('internalId', '==', query.internalId);
    }

    return this.db.collection<Product>('products', _ => dbQuery)
      .valueChanges({ idField: 'id' });
  }

  async getRelatedProducts(relatedProducts: Product[] | _Product[], limit = 5) {
    const products = [];
    return new Promise<Product[]>(resolve => {
      this.db.collection<Product>('products').ref
        .where('type', '==', ProductType.STORE)
        .where('status', '==', 'active')
        .get().then(productsSnap => {
          let categories: Category[] = [];
          relatedProducts.forEach(relProd => {
            categories = [...new Set([...categories, ...relProd.categories])];
          });
          productsSnap.docs.forEach(docu => {
            if (relatedProducts.some(relProd => relProd.id === docu.id)) {
              return;
            }
            let catScore = 0;
            const doc = docu.data();

            categories.forEach(cat => {
              if (doc.categories.some(c => c.id === cat.id)) {
                catScore++;
              }
            });
            doc.categoryScore = catScore;
            // If is soldout is not pushed
            if (!Product.isAllProductSoldOut(doc)) {
              products.push({
                ...doc,
                id: docu.id,
                price: Product.saledPrice(doc),
                isSoldOut: Product.isAllProductSoldOut(doc),
                slug: doc.slug,
                hasVariant: !!doc.variantCount,
                thumbnail: doc.pictureUrl ? MediaFile.addNameSuffix(doc.pictureUrl, 'm') : '',
                exclusive: doc.exclusive,
                featured: doc.featured,
                presale: doc.presale,
                title: doc.title
              });
            }
          });
          products.sort((a, b) => {
            if (a.featured && !b.featured) {
              return -1;
            }
            if (!a.featured && b.featured) {
              return 1;
            }
            if (a.categoryScore > b.categoryScore) {
              return -1;
            }
            if (a.categoryScore < b.categoryScore) {
              return 1;
            }
            return 0;
          });
          resolve(products.slice(0, limit));
        }
      );
    });
  }

  getByVendorId$(id): Observable<Product[]> {
    return this.db.collection<Product>('products', ref => ref
      .where('vendor.id', '==', id))
      .valueChanges({ idField: 'id' });
  }

  getByVendorId(id): Promise<Product[]> {
    const paginator = { pageIndex: 0, pageSize: 10 };
    const sort = { active: 'title.key', direction: 'desc' };
    const filter = {
      vendor_id: [{ value: id, op: '===', key: 'vendor.id' }]
    };
    return this.query(paginator, sort, filter);
  }

  // Variants
  getVariants$(productId): Observable<Product.Variant[]> {
    const collection = this.db.collection<Product>(`products/${productId}/variants`);
    return collection.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = this.normalize(a.payload.doc.data());
        const id = a.payload.doc.id;
        return { id, ...data } as Product.Variant;
      }))
    );
  }

  createId() {
    return this.db.createId();
  }

  // Helpers
  normalize(data: Product) {

    this.appService.upgradePrintProduct(data);

    // //Actualiza los viejos con nuevos printsettings
    // if (data.type === ProductType.PRINT){
    //   //Si las anillas tienen formato viejo se actualiza
    //   if (data.printingGroup.printSettings.ringColor && !data.printingGroup.printSettings.ringColor.id){
    //     data.printingGroup.printSettings.ringColor = this.appService._ringColors.find(ringColor => data.printingGroup.printSettings.ringColor.name === ringColor.name)
    //                                                  ?? this.appService.getPrintSettingDefault(Settings.RING_COLOR);
    //   }
    //   //Si no tiene hardCover se le agregan
    //   if(!data.printingGroup.printSettings.hardCoverFront){
    //     data.printingGroup.printSettings.hardCoverFront = this.appService.getPrintSettingDefault(Settings.HARD_COVER_FRONT)
    //     data.printingGroup.printSettings.hardCoverBack = this.appService.getPrintSettingDefault(Settings.HARD_COVER_BACK)
    //   }
    // }

    data.createdAt = toDate(data.createdAt);
    data.updatedAt = toDate(data.updatedAt);
    return data;
  }

  onChange(): Observable<any> {
    return this.db
      .collection<any>('refresh')
      .doc('products')
      .valueChanges();
  }

  onProductChange(idProduct): Observable<any> {
    return this.collection
      .doc(idProduct)
      .valueChanges();
  }

  onVariantChange(idProduct, idVariant): Observable<any> {
    return this.collection
      .doc(idProduct)
      .collection('variants')
      .doc(idVariant)
      .valueChanges();
  }

  // ElasticSearch

  /***
   * sort:{
   * active: string //(index),
   * direction: 'asc'|'desc')}
   *
   * paginator:{
   *   pageIndex
   *   pageSize
   * }
   *
   * extras:{
   *
   * }
   *
   * filter:{
   * discount: [],
   * featured:[
   *   {value: true, op:="===", key:"featured"}
   * ],
   * status:[
   *  {value: 'active", op:"===", key: "status"}
   * ]
   *
   * }
   */

  async query(paginator: any, sort: any, filter: any, options?: any) {
    const index = 'products';
    const extras = {
      categories_id: {
        nested: 'categories'
      },
      search: {
        type: 'search'
      },
      id: {
        type: 'id'
      },
    };
    let _options: any = null;
    if (options?.body) {
      _options = {
        body: true
      };
    }
    // convertimos los parámetros al formato de ElasticSearch
    const body = this.es.mtToBody(paginator, sort, filter, extras);
    //debe retornar a Datasource los registros
    return this.es.query(index, body, (item: any) => {
      item.createdAt = item.createdAt ? new Date(item.createdAt._seconds * 1000) : null;
      return item;
    });
  }

  sortedVariants$ = (product) => this.getVariants$(product.id).pipe(
    map(variants => {
      return product.variantCount && product.variants ? product.variants.map(variantId => variants.find(v => v.id === variantId)) : []
    }),
    shareReplay(1)
  )

  productWithVariants$ = (id) => this.get$(id).pipe(
    mergeMap(
      product => this.sortedVariants$(product).pipe(
        map(variants => ({ ...product, variants }))
        // if (product.type === 'store' && produc)
      ))
  )

  internalProductWithVariants$ = (internalId) => this.getByQuery({ query: { internalId } }).pipe(
    mergeMap(
      product => this.sortedVariants$(product[0]).pipe(
        map(variants => ({ ...product[0], variants }))
        // if (product.type === 'store' && produc)
      ))
  )


}
