import { Router } from '@angular/router';

import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { first, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MetaReferrer, UserModel } from './user.model';
import { serverTimestamp } from '@angular/fire/firestore';

import { AlertController, ModalController } from '@ionic/angular';
import { AcceptanceModalComponent } from '../components/acceptance-modal/acceptance-modal.component';
import { toDate } from '../shared/utils/utils';
import { FileUploadService } from '../shared/a2-files/services/file-upload.service';
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public user$ = new BehaviorSubject<UserModel>(null);
  public userG: UserModel;
  private readonly collectionName = 'users';

  constructor(
    private authService: AuthService,
    private db: AngularFirestore,
    private storage: AngularFireStorage,
    public router: Router,
    public modalController: ModalController,
    private alertController: AlertController,
    private cookieService: CookieService
  ) {
    this.user.subscribe(user => {
      this.user$.next(user);
      this.userG = user;
      this.checkLoginCookie();
      // console.log("userService",this.userG);
    });
  }

  get role$(): Observable<string> {
    return from(this.authService.authState.getIdTokenResult()).pipe(map(idToken => idToken.claims?.role ?? ''));
  }

  get user(): Observable<any> {
    return this.authService.authState$.pipe(
      switchMap(user => {
        if (!user) {
          // console.log("@@Creamos anonimous");
          this.authService.createAnonymousUser().then(res => {
            // console.log("resultado anonimo:", res);
          });
        } else {
          if (user.isAnonymous === true) {
            // console.log("@@vaya es anonimo");
            //console.log("Uer Anon", user)
            return [{
              id: null,
              isAnonymous: true,
              photoURL: './assets/images/blank-profile.png',
              displayName: ' ', //Usuario Desconocido
              uid: user.uid
            }];
          }
        }
        if (user) {
          // console.log("@@Tenemos User", user);
        }
        return !user
          ? [{
            id: null,
            photoURL: './assets/images/blank-profile.png',
            displayName: ' ' //Usuario Desconocido
          }]

          : this.db
            .collection(this.collectionName, ref => ref.where('uid', '==', user.uid))
            .valueChanges({ idField: 'id' }) // Coloca los valores con el id
            .pipe(map(arr => arr[0]), shareReplay(1)); // Sacamos el primer valor que es el que nos interesa
        //TODO debemos usar el take 1 para que se desconecte o No para poder actualizar los datos. se debe reformar un poco
      }),
      shareReplay(1)
    );
  }

  setUser(data) {
    data.updatedAt = serverTimestamp();

    return Promise.resolve()
      // .then(() => {
      //   if (image) {
      //     return this.uploadImage(`users/${data.id}/`, image, 'profile', type)
      //       .then(path => console.log("User Photo Uploaded:", path)) //data.photoURL = path
      //       .catch( err => {
      //         console.error(err);
      //       });
      //   } else return null;
      // })
      .then(() => this.db.collection(this.collectionName).doc(data.id).update(data));
  }

  createUser(data) {
    data.createdAt = serverTimestamp();
    data.updatedAt = serverTimestamp();
    data = this.setInitialAcceptance(data);
    return this.db.collection(this.collectionName).add(data);
  }

  // uploadImage(path, image, name, type?){
  //   return this.storage.ref(`${path}${name}.${type}`)
  //   .putString(image, 'data_url', {contentType: `image/${type}`})
  //   .then(res =>  res.ref.getDownloadURL());
  // }

  /** upsertUserLogin
   * Verifica si ya existe por el uid
   * Si existe actualiza ciertos valores
   * Si no existe agrega el usuario a Users
   */
  upsertUserLogin(data) {
    // console.log(data.uid);
    data.updatedAt = serverTimestamp();
    return this.db.collection(this.collectionName, ref => ref.where('uid', '==', data.uid).limit(1))
      .valueChanges({ idField: 'id' })
      .pipe(first(), map(arr => arr[0]))
      .toPromise()
      .then(user => {

        if (!user) {
          // console.log("@@@-> Data on CREATE", data);
          data.createdAt = serverTimestamp();
          data = this.setInitialAcceptance(data);
          this.db.collection(this.collectionName).add({ ...data });
        } else {
          // console.log("@@@-> User on upsert", user);
          //console.log("@@@-> Data on upsert", data);
          let uData: any = {};
          if (data.phoneNumber) {
            uData.phoneNumber = data.phoneNumber;
          }
          uData.emailVerified = data.emailVerified;
          if (data.firstName) {
            uData.firstName = data.firstName;
          }

          if (data.lastName) {
            uData.lastName = data.lastName;
          }

          if (data.photoURL) {
            uData.photoURL = data.photoURL;
          }

          // console.log("uData pa actualizar", uData);
          uData.updatedAt = serverTimestamp();
          uData = this.setDefaultAcceptance(uData);
          this.db.collection(this.collectionName).doc(user.id).update(uData);
        }

        return user;
      });
  }

  setDefaultAcceptance(data) {
    data['acceptance.privacy'] = true;
    data['acceptance.tos'] = true;
    return data;
  }

  setInitialAcceptance(data) {
    data.acceptance = {
      privacy: true,
      tos: true,
      referrer: null,
      ads: null,
      printProtected: null
    };
    return data;
  }

  doSignup(value) {
    const { email, password, ...data } = value;
    return this.authService.doRegister({ email, password })
      .then(user => {
        //eliminar password de data
        return this.createUser({ ...user, ...data });
      })
      .catch();
  }

  doSignupAnonym(value) {
    const { email, password, ...data } = value;
    return this.authService.doLinkEmail({ email, password })
      .then(user => {
        user.email = email;
        return this.createUser({ ...user, ...data });
      })
      .catch();
  }

  async doEmailLogin(credential) {
    return this.authService.doEmailLogin(credential)
      .then(auth => {
        return this.upsertUserLogin(auth.user);
      })
      .catch(async err => {
        await this.handleAuthError(err);
        throw err;
      });
  }

  async doSignupGoogle() {

    //return this.authService.goPopupLogin('google.com')
    return this.authService.doLogin('google.com')
      .then(user => {
        // console.log("@@User Google", user);
        return this.upsertUserLogin(user);
      })
      .catch(async err => {
        // console.log('===>> ERROR do sign up Google:');
        // console.log(err);
        await this.handleAuthError(err);
        throw err;
      });
  }

  doSignupFacebook() {
    //return this.authService.goPopupLogin('facebook.com')

    return this.authService.doLogin('facebook.com')
      .then(user => {
        return this.upsertUserLogin(user);
      })
      .catch(async err => {
        // console.log('===>> ERROR do sign up FB:');
        // console.log(err);
        await this.handleAuthError(err);
        throw err;
      });
  }


  //Solicitar aceptacion antes e Login.
  async presentAcceptance(isRecheck = false) {
    const modal = await this.modalController.create({
      component: AcceptanceModalComponent,
      cssClass: 'auto-height',
      backdropDismiss: !isRecheck,
      componentProps: {
        'isRecheck': isRecheck,
      }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();

    return data;
  }

  async handleAuthError(err) {
    if (!err) {
      return;
    }
    switch (err.code) {
      case 'auth/email-already-in-use': // MN: i think... this one when it exists but has no psw
      case 'auth/account-exists-with-different-credential':  // this one when exists and has a psw (just guessing...)
        console.log('===>> handleAuthError');
        const alert = await this.alertController.create({
          // cssClass: 'my-custom-class',
          header: 'Cuenta ya existe',
          subHeader: 'Ya existe una cuenta con este correo electrónico',
          message: 'Entra con tu otra cuenta. Puedes recuperar tu contraseña si no la recuerdas.',
          buttons: ['OK']
        });
        await alert.present();
        break;
    }
  }

  getUserByEmail(email) {
    return this.db.collection(this.collectionName, ref => ref.where('email', '==', email).limit(1))
      .get()
      .toPromise()
      .then(docs => {
        let user = {} as any;
        if (docs) {
          docs.forEach(r => {
            const id = r.id;
            const data = r.data() as object;
            user = { id, ...data };
          });
        }
        return user;
      })
      .catch(err => {
        console.error(err);
        return {};
      });
  }

  getBySlug$(slug): Observable<UserModel> {
    return this.db.collection<UserModel>(this.collectionName, ref => ref
      .where('slug', '==', slug)
      .limit(1))
      .valueChanges({ idField: 'id' })
      .pipe(map(arr => arr[0]));
  }

  getInbox(limit: number = 200) {
    return this.db.collection(this.collectionName).doc(this.userG.id).collection<any>('inbox', ref => ref.where('spam', '==', false).orderBy('createdAt', 'desc').limit(limit))
      .snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const data = a.payload.doc.data();// as Incidence; Inbox
          const id = a.payload.doc.id;
          return { id, ...data };
        }))
      );
  }

  updateInbox(data) {
    const id = data.id;
    delete data.id;
    this.db.collection(this.collectionName).doc(this.userG.id).collection('inbox').doc(id).update(data);
  }

  getMessage(id) {
    return this.db.collection(this.collectionName).doc(this.userG.id).collection('inbox').doc(id).valueChanges()
      .pipe(
        take(1)
      )
      .toPromise();
  }

  setToken(data) {
    return this.db.collection(this.collectionName).doc(this.userG.id).collection('tokens').add({ ...data });
  }

  getCreators$(limit): Observable<UserModel[]> {
    return this.db.collection(this.collectionName, ref => ref
      .where('isCreator', '==', true)
      .where('featured', '==', true)
      .limit(limit))
      .snapshotChanges()
      .pipe(
        map(actions => actions.map(a => {
          const data = this.normalize(a.payload.doc.data());
          const id = a.payload.doc.id;
          return { id, ...data } as UserModel;
        }))
      );
  }

  //Backend to App
  normalize(item) {
    item.createdAt = toDate(item.createdAt);
    item.updatedAt = toDate(item.updatedAt);
    return item;
  }


  /**
   * Loads and store the file to upload
   * @param event the file to upload
   * @param userId
   * @param fileUploadService
   */
  setPhotoProfile(event, userId, fileUploadService: FileUploadService): Promise<void> {
    const file: File = fileUploadService.getFile(event);
    if (!file) {
      console.error('File con problemas');
      return null;
    } else {
      const collectionPhoto = 'files';
      const path = `${this.collectionName}/${userId}/${collectionPhoto}`;
      return fileUploadService.uploadFile(file, path, path, 'profilePicture');
    }

  }


  setUserMetaReferrer(data: MetaReferrer): Promise<any> {
    const { id, ..._data } = data as any;
    // console.log({id,_data,data});
    // data.createdAt = serverTimestamp();
    // data.updatedAt = serverTimestamp();
    return this.db.collection(this.collectionName).doc(this.userG.id).collection('meta').doc('referrer').set({ ..._data }, { merge: true });
  }

  async getUserMetaReferrer(): Promise<any> {
    return await this.db.firestore.collection(this.collectionName).doc(this.userG.id).collection('meta').doc('referrer').get()
      .then(snap => {
        // const id = snap.id;
        const data = snap.data() as MetaReferrer;
        let value = { ...data };
        return value;
      });
  }

  UserMetaReferrer$(userId: string): Observable<MetaReferrer> {
    return this.db.collection(this.collectionName).doc(userId).collection('meta').doc('referrer')
      .valueChanges().pipe(
        map(snap => {
          // console.log(snap);
          return snap as MetaReferrer;
        })
      );
  }

  public ambassadorProducts$(userId: string): Observable<any> {
    return this.db.collection(this.collectionName).doc(userId).valueChanges().pipe(
      switchMap((user: UserModel) => {
        const ambassadorProducts = user.ambassadorProducts || []; // Obtén los IDs de los productos del usuario o utiliza un array vacío si no hay ninguno

        // Realiza la consulta para obtener los productos correspondientes a los IDs
        const productQueries = ambassadorProducts.map(amb => this.db.collection('products').doc(amb.productId).valueChanges());

        // Combina los resultados de las consultas en un solo Observable
        return productQueries.length > 0 ? combineLatest(productQueries) : of([]);
      }),
      map(products => products.filter(product => product !== null)) // Filtra los productos nulos (si algún ID no existe)
    );
  }

  private checkLoginCookie() {
    const loginCookie = this.cookieService.get('loginCopyfly');
    let pictureURL = this.userG?.photoURL;
    if (this.userG?.isAnonymous) {
      if (loginCookie) {
        this.cookieService.delete('loginCopyfly', '/', '.copyfly.es');
        this.cookieService.delete('nameLogin', '/', '.copyfly.es');
      }
    } else {
      if (!pictureURL?.includes('http')) {
        pictureURL = 'http://copyfly.es' + pictureURL?.substring(1);
      }
      if (!loginCookie || pictureURL !== loginCookie) {
        this.cookieService.set('loginCopyfly', pictureURL, 999, '/', '.copyfly.es');
        this.cookieService.set('nameLogin', this.userG?.displayName, 999, '/', '.copyfly.es');
      }
    }
  }


}

