import { Component, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";

@Component({
  selector: 'app-print-permission-policy',
  templateUrl: './print-permission-policy.component.html',
  styleUrls: ['./print-permission-policy.component.scss'],
})
export class PrintPermissionPolicyComponent implements OnInit {
  check = false;

  constructor(private modalCtrl: ModalController) {
  }

  ngOnInit() {
  }

  closeModal(action) {
    this.modalCtrl.dismiss({action});
  }

  changeCheck() {
    this.check = !this.check;
  }
}
