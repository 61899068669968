import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavParams, ToastController } from '@ionic/angular';
import { combineLatest, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { OutputMessage } from 'src/app/models/models';
import { ProductPageModel } from 'src/app/models/product-ui.model';
import { ProductHandle, productSelected$ } from 'src/app/shop/productos/handler/product-handler';
import {
  OnProductCardInfoActions
} from 'src/app/shop/productos/product-part-info-interaction/product-part-info-interaction.component';
import { AppService } from '../../app.service';
import { CartService } from '../../cart.service';
import { ProductBlService } from '../../services/productBl.service';
import { MediaFile } from "../../../models/media.model";
import { AlertModalComponent } from "../../../components/alert-modal/alert-modal.component";
import { Product, ProductSubType } from "../../../models/product.model";
import highestDiscount = Product.highestDiscount;

@Component({
  selector: 'app-add-cart-popup',
  templateUrl: './add-cart-popup.component.html',
  styleUrls: ['./add-cart-popup.component.scss'],
})
export class AddCartPopupComponent extends ProductHandle implements OnInit, OnProductCardInfoActions {
  @Input() measurement;
  private componentDestroyed = new Subject();
  productId: string;
  highestDiscount = 0;
  // qty = 1;
  // price = 0;
  unitPrice = 0;
  optionsSelected = null;

  // _variantSelected = new BehaviorSubject<Product.Variant>(null);


  // product$;
  // productSelected$;
  // variantSelected$

  constructor(
    public appService: AppService,
    public cartService: CartService,
    public navParams: NavParams,
    protected productBlService: ProductBlService,
    protected route: ActivatedRoute,
    // private popover: PopoverController,
    private popover: ModalController,
    private toastController: ToastController,
    private modalController: ModalController
  ) {
    super();

    this.productId = this.navParams.get('productId');
    this.product$ = this.productBlService.productWithVariants$(this.productId).pipe(
      tap(product => {
        this.highestDiscount = highestDiscount(product.variants)
        // console.log(product);
        // this.unitPrice = product.unitPrice.amt;
        // this.setPrice();

      })
    );
    this.productSelected$ = productSelected$(this.product$, this._qty, this._variantSelected)

  }


  ngOnInit() {

    // Suscribtion to know wich variant was selected
    combineLatest([this.product$, this.route.queryParamMap])
      .pipe(
        map(([product, params]) => {
          const variantId = params.get('variant');
          if (!product) return null;

          // console.log("__________",variantId);
          return variantId
            ? product.variants.find(variant => variant.id === variantId)
            : product.variants.length
              ? product.variants[0]
              : null
        }),
        takeUntil(this.componentDestroyed)
      ).subscribe(this._variantSelected)
  }

  ngOnDestroy() {
    this.componentDestroyed.next(null);
    this.componentDestroyed.unsubscribe();
  }

  close() {
    this.dismiss();
  }

  dismiss() {
    this.popover.dismiss(
      {
        qty: this._qty.value
      }
    );
  }

  onQtyChange(event) {
    this._qty.next(event.qty);
  }

  async onAdd(product: ProductPageModel) {
    // console.log("Se deben colocar los cambios")
    if ((this.cartService.cart.items.some(it => !it.product.presale) && product.presale) ||
      (this.cartService.cart.items.some(it => it.product.presale) && !product.presale)) {
      const modal = await this.modalController.create({
        component: AlertModalComponent, // AddressComponent,
        componentProps: {
          msg: 'Con el fin de poder respetar los tiempos de entrega no es posible combinar otros productos junto a un producto en preventa, ya que este se encuentra en proceso de producción. Por favor realiza 2 pedidos independientes.'
        },
        cssClass: 'auto-height'
      });
      await modal.present();
      return;
    }
    this.productBlService.addProduct(product, this._qty.value);
    this.dismiss();
    const toast = await this.toastController.create({
      message: 'El producto se ha añadido al carrito',
      duration: 4000,
      color: 'primary',
      cssClass: 'fitWidthToast'
    });
    await toast.present();
  }

  onVariantChange(event) {
    this._variantSelected.next(event.variant);
  }

  onUpdateOptionsSelected(event) {
    this.optionsSelected = event;
  }

  public onAction(event: OutputMessage) {
    event.invoke(this);
  }

  public readonly MediaFile = MediaFile;
  protected readonly ProductSubType = ProductSubType;
}
