// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 65px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtaW5mby1wYXBlci13ZWlnaHQuY29tcG9uZW50LnNjc3MiLCIuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFxDb3B5Zmx5JTIwMlxcRGVza3RvcFxcRGVzYXJyb2xsb1xcY29waXN0ZXJpYS1hcHAtaW9uaWNcXHNyY1xcYXBwXFxob21lLW5ld1xcdG9vbHRpcC1pbmZvLWNvbXBvbmVudHNcXHRvb2x0aXAtaW5mby1wYXBlci13ZWlnaHRcXHRvb2x0aXAtaW5mby1wYXBlci13ZWlnaHQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0FDQ0YiLCJmaWxlIjoidG9vbHRpcC1pbmZvLXBhcGVyLXdlaWdodC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbImltZyB7XHJcbiAgbWF4LXdpZHRoOiA2NXB4O1xyXG59XHJcbiIsImltZyB7XG4gIG1heC13aWR0aDogNjVweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/home-new/tooltip-info-components/tooltip-info-paper-weight/tooltip-info-paper-weight.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/home-new/tooltip-info-components/tooltip-info-paper-weight/tooltip-info-paper-weight.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;ACCF;ADCA,wpBAAwpB","sourcesContent":["img {\r\n  max-width: 65px;\r\n}\r\n","img {\n  max-width: 65px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
