import {Fees} from './fee.model';

export namespace Setting {

  export interface General {
    homeBanner: string;
    fees: Fees;
    influencerText: string;

    threshold?: {
      blocked?: boolean,
      delayed?: boolean
    },
    minVersion: string;

    cartMessage?: CartMessage
  }

  export interface AppBanners {
    [page: string]: Banners;
  }

  export interface Banners {
    [name: string]: Array<Banner>;
  }

  export interface Banner {
    id: string;
    title: string;
    image: string;
    targetBlank: boolean;
    url: string;
  }

  export interface CartMessage {
    message: string;
    color: string;
    enabled: boolean;
  }

  export interface PopupBeforePay {
    enabled: boolean;
    quantityToShow: number;
  }
}


