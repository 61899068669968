import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[onlyNumbers]'
})
export class OnlyNumberDirective {

  constructor(private _el: ElementRef) { 
    var reg = /^[0-9]$/;
    _el.nativeElement.onkeypress = function(e) {
      if (!reg.test(e.key)){
        e.preventDefault();
        return;
      }
    };    
  }

}
