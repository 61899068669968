import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { MenuController, ModalController, Platform } from '@ionic/angular';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { CartService } from './shared/cart.service';
import { environment } from '../environments/environment';
import { OrderStateService } from './shared/order-state.service';
import { AnalyticsService } from './shared/analytics.service';
import { AcceptanceService } from './shared/acceptance.service';
import { AppCoreService } from './core/app-core.service';
import { SharedLibService } from 'shared-lib';
import { AppService } from './shared/app.service';
import { register } from 'swiper/element/bundle';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { filter } from "rxjs/operators";
import { Meta, Title } from "@angular/platform-browser";
import { MetaTagsService } from "./shared/meta-tags.service";

// import { PrivacyPolicyPage } from './privacy-policy/privacy-policy.page';
// import { TermsOfServicePage } from './terms-of-service/terms-of-service.page';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  version = environment.version;


  public user: any;

  constructor(
    private analytics: AngularFireAnalytics,
    private authService: AuthService,
    public userService: UserService,
    public router: Router,
    public modalController: ModalController,
    private gtmService: GoogleTagManagerService,
    private GTAnalytics: AnalyticsService,
    private acceptanceService: AcceptanceService,
    public cartService: CartService,
    private orderStateService: OrderStateService,
    private sharedLibService: SharedLibService,
    public appCoreService: AppCoreService,
    public appService: AppService,
    private menuCtrl: MenuController,
    private metaTagsService: MetaTagsService,
    private titleService: Title
  ) {

    this.initCookieConsent();

    this.gtmService.addGtmToDom();
    this.addMapsApiToDom();
    this.initializeApp();
    this.sharedLibService.testInit();
    this.analytics.logEvent('app_open', { "component": "AppComponent" });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (environment.production) {
        this.handleRouteChangeMeta();
      }
    });
  }

  initCookieConsent() {
    if (environment.production) {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = "https://cdn.cookie-script.com/s/12646ef1e2c6919ecef016e8621c9320.js";
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }

  async initializeApp() {
    this.userService.user$.subscribe(user => this.normalizeUser(user));
  }

  async normalizeUser(user) {

    if (user && user.id && !user.acceptance?.privacy && !this.justSignIn()) {
      if (await this.userService.presentAcceptance(true)) {
        const respId = this.acceptanceService.userAcceptance(user);
        if (respId) {
          this.userService.setUser({
            id: user.id,
            ['acceptance.privacy']: true,
            ['acceptance.tos']: true
          });
        }
      } else {
        this.doLogout();
      }
    }
  }

  justSignIn() {
    const today = new Date() as any;
    const lastSignIn = new Date(this.authService.authState.metadata.lastSignInTime) as any;
    const diffTime = Math.abs(today - lastSignIn);
    return (diffTime < (1000 * 10));
  }

  ngOnInit() {
    // this.GTAnalytics.init();
    //this.GTAnalytics.testEvent();

  }

  async doLogout() {
    await this.authService.doLogout();
    this.router.navigate(['']);
    await this.orderStateService.resetOrderAsync();
    setTimeout(() => window.location.reload(), 1);
  }

  isDevelopmentContext() {
    return !environment.production;
  }

  // async showPrivacyModal() {
  //   const modal = await this.modalController.create({
  //     component: PrivacyPolicyPage
  //   });
  //   return await modal.present();
  // }
  // async showTermsModal() {
  //   const modal = await this.modalController.create({
  //     component: TermsOfServicePage
  //   });
  //   return await modal.present();
  // }

  // mouseEnter(){
  //   this.menuExpanded = true;
  // }

  // mouseLeave(){
  //   this.menuExpanded = false;
  // }

  // isDesktop(){
  //   return false;
  //   // const isDesktop = window.matchMedia("(min-width: 992px)").matches;
  //   // return window.matchMedia("(min-width: 992px)").matches
  // }


  closeMenu() {
    this.menuCtrl.close()
  }

  private handleRouteChangeMeta() {
    // Aquí puedes poner lógica para determinar qué meta tags deberían ser activadas
    // basado en la ruta actual, por ejemplo:
    const url = this.router.url;
    if (url === '/') {
      this.metaTagsService.updateMetaTags([
        {
          name: 'description',
          content: 'Imprime online y recibe en un fly por 0,01 BN y 0,06 C, usando el cupón CFLY con un 5% dto. Envíos gratis a partir de 35 €. '
        },
        { name: 'robots', content: 'index, follow' }
      ]);
      this.titleService.setTitle('Imprime online y recibe en un fly | 0,01 BN y 0,06 C | Usa CFLY 5% dto.')
    } else if (url.startsWith('/papeleria') || url.startsWith('/tienda')) {
      this.metaTagsService.updateMetaTags([
        {
          name: 'description',
          content: 'Libretas con papeles de colores, pósits transparentes pasteles, subrayador negro Stabilo y mucho más, envíos en 24 horas, entra en la papelería online más aesthetics. '
        },
        { name: 'robots', content: 'index, follow' }
      ]);
      this.titleService.setTitle('La papelería online más aesthetics | Envíos en 24 horas ')
    } else {
      this.metaTagsService.updateMetaTags([
        {
          name: 'description',
          content: 'Imprime online y recibe en un fly por 0,01 BN y 0,06 C, usando el cupón CFLY con un 5% dto. Envíos gratis a partir de 35 €. '
        },
        { name: 'robots', content: 'noindex' }
      ]);
      this.titleService.setTitle('Imprime online y recibe en un fly | 0,01 BN y 0,06 C | Usa CFLY 5% dto.')
    }
  }

  private addMapsApiToDom() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://maps.googleapis.com/maps/api/js?key=${environment.firebaseConfig.apiKey}`;
    const head = document.getElementsByTagName('head')[0];
    if (head !== null && head !== undefined) {
      document.head.appendChild(script);
    }
  }

  toStationery() {
    let queryParams = {page: 0}
    let navigationExtras: NavigationExtras = {
      queryParams
    };
    this.router.navigate(['papeleria/catalogo'], navigationExtras );
  }
}
