import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { EmmiterMessage, OutputMessage, ReceptorMessage } from 'src/app/models/models';
import { ProductPageModel } from 'src/app/models/product-ui.model';
import { Product, ProductSubType } from 'src/app/models/product.model';
import { CartService } from "../../../shared/cart.service";
import { ProductCustomElementsComponent } from '../product-custom-elements/product-custom-elements.component';

export interface OnProductCardInfoActions extends ReceptorMessage {
  onVariantChange(event): void;

  onQtyChange(event): void;

  onAdd(product: ProductPageModel): void;
}

@Component({
  selector: 'app-product-part-info-interaction',
  templateUrl: './product-part-info-interaction.component.html',
  styleUrls: ['./product-part-info-interaction.component.scss']
})
export class ProductPartInfoInteractionComponent implements OnInit, EmmiterMessage {

  @Input() product: ProductPageModel;
  @Input() variantSelected$: Observable<Product.Variant>;//
  @Input() isSummary: boolean;
  @Input() measurement: boolean;
  @Input() galleries;
  @Input() highestDiscount;

  @Output() action = new EventEmitter<OutputMessage>();

  @ViewChild(ProductCustomElementsComponent, {static: false}) productCustomElementsComponent: ProductCustomElementsComponent;

  constructor(private cartService: CartService) {
  }

  ngOnInit(): void {
    //console.log("Product in product-part-info-interaction:", this.product)
  }

  resetNotebookOptions() {
    var comp = this.productCustomElementsComponent;
    if (comp) {
      comp.initializeCustomOptions();
      //console.log('Opciones:', comp.optionsSelected);
    }
  }


  emitAction(type: string, value?) {
    this.action.emit(new OutputMessage(type, value));
  }

  addQtyCustomProduct(event) {
    this.product.price = Product.saledPrice(this.product);
    this.product.qty = event.qty;
    this.product.hasVariant = false;
    this.product.canAdd = true;
    this.product.isSoldOut = false;
  }

  maxQtyToAdd(product: ProductPageModel) {
    const productInCart = this.cartService.cart.items.find(it => it.product.id === product.id && it.product.variantId === product.variantId)
    return productInCart ? product.stock - productInCart.qty : product.stock;
  }

  protected readonly ProductSubType = ProductSubType;
}
