// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg {
  width: 25px;
  margin-right: 10px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVwbG9hZC1maWxlLXBvcG92ZXIuY29tcG9uZW50LnNjc3MiLCIuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXENvcHlmbHklMjAyXFxEZXNrdG9wXFxEZXNhcnJvbGxvXFxjb3Bpc3RlcmlhLWFwcC1pb25pY1xcc3JjXFxhcHBcXGNvbXBvbmVudHNcXHVwbG9hZC1maWxlLXBvcG92ZXJcXHVwbG9hZC1maWxlLXBvcG92ZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxXQUFBO0VBQ0Esa0JBQUE7QUNDRiIsImZpbGUiOiJ1cGxvYWQtZmlsZS1wb3BvdmVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsic3ZnIHtcclxuICB3aWR0aDogMjVweDtcclxuICBtYXJnaW4tcmlnaHQ6IDEwcHg7XHJcbn1cclxuIiwic3ZnIHtcbiAgd2lkdGg6IDI1cHg7XG4gIG1hcmdpbi1yaWdodDogMTBweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/upload-file-popover/upload-file-popover.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/components/upload-file-popover/upload-file-popover.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;ACCF;ADCA,wpBAAwpB","sourcesContent":["svg {\r\n  width: 25px;\r\n  margin-right: 10px;\r\n}\r\n","svg {\n  width: 25px;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
