import { ApplicationRef, Injectable } from '@angular/core';
import { isNil } from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AppCoreService } from '../core/app-core.service';
import { UserService } from '../core/user.service';
import { CouponService } from '../coupons/coupon.service';
import {
  Order,
  OrderPaymentStatus,
  OrderProcessStatus,
  OrderShippingStatus,
  OrderType,
  PaymentType,
  PostalAddress
} from '../models/models';
import { ShippingType } from '../models/shipping-settings.model';
import { AppService } from './app.service';
import { CartService } from './cart.service';
import { OrderDraftService } from './order-draft.service';
import { OrderService } from './order.service';
import { EncryptionService } from "./encryption.service";
import { ShippingService } from "./services/shipping.service";
import { WalletService } from '../wallet/wallet.service';
import { ProductType } from '../models/product.model';
import { FormGroup } from "@angular/forms";


//Pendiente
//http://localhost:8100/cart?orderId=idx75pV3CD2rWtD5FpoE
//Fail
//http://localhost:8100/cart?orderId=NxTBTDDHi9VMCrnELzt2

@Injectable({
  providedIn: 'root'
})
export class OrderStateService {
  private readonly _loading = new BehaviorSubject<boolean>(false);
  private readonly _order = new BehaviorSubject<Order>(null);
  readonly order$ = this._order.asObservable();
  readonly headerMessage$: Observable<string> = this.order$.pipe(
    map(order => {
      let msg;
      // //console.log('=====>>>> GOT ORDER:');
      if (order?.type === OrderType.COMPLEMENTARY && order?.linkedOrders?.length > 0) {
        msg = `Pedido complementario de #${order.linkedOrders[0].number}`;
      }
      return msg;
    })
  );
  private userSubscription: Subscription;

  constructor(
    private userService: UserService,
    private cartService: CartService,
    private shippingService: ShippingService,
    private couponService: CouponService,
    private walletService: WalletService,
    private orderDraftService: OrderDraftService,
    private orderService: OrderService,
    private appService: AppService,
    private appCoreService: AppCoreService,
    private appRef: ApplicationRef,
    private encryptS: EncryptionService
  ) {
    this.appCoreService.isAppReady$.pipe(
      first(isAppReady => isAppReady)
    )
      .subscribe(isAppReady => {
        this._order.next(this.newOrder());
        this.initService()
      })

    // this.appRef.isStable.pipe(
    //   first(stable => stable),
    // ).subscribe(() => {
    //   console.log("Es estable alguna vez ?")
    //   this.userService.user$.subscribe(user => this.setUserOrder(user) );
    // });

  }

  subscribeUser(witSubscription: boolean) {
    const isSubscribed = this.userSubscription && !this.userSubscription.closed
    if (!witSubscription && isSubscribed) {
      this.userSubscription.unsubscribe()
    }
    if (witSubscription && !isSubscribed) {
      this.userService.user$.subscribe(user => this.setUserOrder(user));
    }
  }

  async initService() {
    // console.log("Init Service")
    const savedOrder = await this.getStorageOrder();
    if (savedOrder) {
      this.setOrder(savedOrder);
    }

    this.cartService.onCartRefreshed().subscribe(() => {
      if (this.cartService.onlyWalletBonus) {
        this.updateProperty({ shippingType: this.shippingService.isShippingTypeAvailable(ShippingType.ECONOMIC, this._order.value.shippingAddress.postalCode) && !this.cartService.addressIsCanaryOrBalearics() ? ShippingType.ECONOMIC : ShippingType.STANDARD });
      }
      if (this.cartService.freeShipping && this._order.value.shippingType === ShippingType.ECONOMIC) {
        this.updateProperty({ shippingType: ShippingType.STANDARD });
      }
    });
    // this._loading.pipe(
    //   filter(loading => !loading),
    //   tap(loading => //console.log("@@@@@@@loading", loading)),
    //   switchMap(loading => loading ? EMPTY :  this.userService.user$)
    // )    
    // .subscribe(user => { 
    //   //console.log("##setUserOrder 2", user)
    //   this.setUserOrder(user) 
    // })
  }

  async setOrder(order: Order = null) {
    this._order.next(order || this.newOrder())
    if (!order && this.userService.userG?.id) {
      const user = await this.userService.user;
      this.setUserOrder(user);
    }
  }

  updateProperty(data, orderFormGroup: FormGroup = null) {

    // Para no cambiar el valor de "name" se separa del orderFormGroup cuando este existe
    // Queremos que "name" sea igual al nombre del usuario
    const {name, ...orderFormGroupWithoutName} = orderFormGroup?.value || {};
    const currentOrderData = !!orderFormGroup ? { ...this._order.value, ...orderFormGroupWithoutName } : { ...this._order.value }
    this._order.next({ ...currentOrderData, ...data });
  }

  async setUserOrder(user) {
    // console.log("######-> user", user);
    this.cartService.userCoupon = null;
    const order = this._order.value;

    const preserveId = order.type === OrderType.COMPLEMENTARY;


    let dataUser = {} as any;

    if (user && user.id) {
      dataUser = {
        // policy: user.policy ?  user.policy : order.policy,
        ads: !isNil(user.acceptance?.ads) ? user.acceptance.ads : null
      };

      if (user.id !== order.userId) {
        this.cartService.userCoupon = await this.couponService.userCoupon(user.id);
        dataUser = {
          ...dataUser,
          // id: preserveId ? order.id : null,
          // id: order.id,
          userId: user.id,
          name: user.displayName,
          email: user.email,
          phone: user.phoneNumber,
          gender: user.gender ?? null,
          ocupation: user.ocupation ?? null
        }
        if (user.billingAddress) {
          dataUser.billingAddress = user.billingAddress
        }
        ;
        if (user.shippingAddress) {
          dataUser.shippingAddress = user.shippingAddress
        }
        ;
      }

      // this.cartService.applyWallet(true);
    } else {
      // if (!preserveId){
      //   dataUser = {
      //     ...dataUser,
      //     userId: null,
      //     name: '',
      //     email: '',
      //     phone: '',
      //     policy: null,
      //     ads: null
      //   } 
      //}
      this.cartService.applyWallet(false);
    }
    //console.log("dataUser", dataUser);
    this.updateProperty(dataUser);
    this.cartService.setCouponStatus();

  }

  async loadSavedOrder(order: Order) {
    this._loading.next(true);
    this.resetOrder()

    // //console.log('////////////==> CASO A loadSavedOrder');

    // //console.log("OOOOrder", order);


    //TODO terminar de completar los casos
    this.cartService.cart = { ...order.cart };
    order.cart = null;

    //Seteamos a quien pertenece el fee
    if (order.userId) {
      this.appService.fee = {
        userId: order.userId,
        type: 'share'
      };
    }
    // await this.userService.user$.pipe(
    //   filter(user => !!user),
    //   take(1)
    // ).toPromise();
    // //console.log("this.userService.userG", this.userService.userG)

    if ([OrderPaymentStatus.PAID, OrderPaymentStatus.REFUNDED].includes(order.paymentStatus)) {

      //si la orden está pagada se utilizará para recomprar lo mismo, es como compartir
      this.cartService.cart.discounts = [];
    } else {
      let {
        id,
        number,
        userId,
        name,
        email,
        phone,
        policy,
        ads,
        billingAddress,
        shippingAddress,
        type,
        linkedOrders
      } = order;
      if (this.userService.userG?.id !== order.userId) {
        userId = this.userService.userG?.id;
        name = '';
        email = this.userService.userG?.email;
        phone = this.userService.userG?.phoneNumber;
        policy = null;
        ads = null;
      }
      this.updateProperty({
        id,
        number,
        userId,
        name,
        email,
        phone,
        billingAddress,
        shippingAddress,
        type,
        linkedOrders
      })
      // if (order.type === OrderType.COMPLEMENTARY 
      //   || (this.userService.userG?.id && (!order.userId || this.userService.userG?.id === order.userId))
      //   ) {
      //   //Estamos manteniendo el id para que pueda repagar una orden No pagada
      //   const {id, number, userId, name, email, phone, policy, ads, billingAddress, shippingAddress, type, linkedOrders} = order;
      //   this.updateProperty({id, number, userId, name, email, phone, billingAddress, shippingAddress, type, linkedOrders})
      // }
    }
    this.cartService.setProductUpdates();
    this.cartService.checkPrintFiles();
    this._loading.next(false);
  }


  //TODO Unificar criterios entre loadSavedOrder y esta
  //3 puntos a verificar id, ordernumber, userId y estado del pago.

  //El reintentar PAgo no es correcto si no se guarda el id
  //Diferenciar si viene por url o directo ?
  //Si tiene id la orden y está pagada ? si es transferencia
  loadOrder(order: Order) {
    //console.log('////////////==> CASO B loadOrder');
    // //console.log(order);
    this.resetOrder();
    this.cartService.cart = { ...order.cart };
    //TODO verificar si se debe colocar order.cart en null inclusive hacer una copia (esta es la orden guardada como tal)
    // order.cart = null;

    //El asunto radica en cuales datos reutilizamos
    //creo qu epodemos establecer una regla generica 
    //si está guardado o logueado el userId es del logueado
    let { name, userId, email, phone, policy, ads, billingAddress, shippingAddress } = order;
    const data = { userId, name, email, policy, ads, phone, billingAddress, shippingAddress };

    if (this.userService.userG.id) {
      for (const property in data) {
        if (property === 'phone') {
          data.phone = this.userService.userG.phoneNumber ?? data.phone;
        } else {
          data[property] = this.userService.userG[property] ?? data[property]
        }
      }
    }

    this.updateProperty(data)
  }

  saveDraft(): Promise<any> {
    const order = { ...this._order.value };
    order.cart = this.cartService.cart;
    order.id = null;
    order.number = null;
    order.paymentStatus = OrderPaymentStatus.PENDING;
    order.processStatus = OrderProcessStatus.PENDING;
    order.shippingStatus = OrderShippingStatus.PENDING;
    order.paymentType = null; //PaymentType.CREDITCARD;
    order.paymentService = null;
    return this.orderDraftService.addDraft(order);
  }

  resetOrder() {
    this.removeStorageOrder();
    this.setOrder();
    this.cartService.resetCart()
  }

  async resetOrderAsync() {
    this.removeStorageOrder();
    await this.setOrder();
    this.cartService.resetCart()
  }


  newOrder(): Order {
    return {
      _version: 1,
      _appVersion: environment.version,
      id: null,
      userId: null,
      number: null,
      name: '',
      email: '',
      phone: '',
      cart: null,
      paymentStatus: OrderPaymentStatus.PENDING,
      processStatus: OrderProcessStatus.PENDING,
      shippingStatus: OrderShippingStatus.PENDING,
      paymentType: PaymentType.CREDITCARD, //null
      paymentService: null,
      shippingType: null,
      pickupAddress: null,
      billingAddress: this.newAddress(),
      shippingAddress: this.newAddress(),
      // test undefined, last null
      policy: null,
      ads: null,
      type: OrderType.REGULAR,
      linkedOrders: null,
    }
  }

  newAddress(): PostalAddress {
    return {
      recipient: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      province: '',
      provinceId: null,
      postalCode: '',
      country: '',
      phone: '',
      organization: '',
      taxId: '',
      dni: '',
    }
  }


  setStorageOrder(_order) {
    // //console.log('==> setStorageOrder');
    // //console.log(_order);
    const order = { ..._order }
    delete order.cart;
    //TODO optimizar el guardado y ver bien cuales propiedades se debe guardar 
    localStorage.setItem('order', this.encryptS.encrypt(JSON.stringify(order)));
  }

  async getStorageOrder() {
    try {
      let order = JSON.parse(this.encryptS.decrypt(localStorage.getItem('order')));
      // //console.log('==> getStorageOrder', order);
      if (order?.id) {
        const savedOrder = await this.orderService.get(order.id);
        if ([OrderPaymentStatus.REFUNDED, OrderPaymentStatus.PAID].includes(savedOrder?.paymentStatus)) {
          this.resetOrder();
          order = null;
        }
        // //console.log('==> getSavedOrder', savedOrder);
      }

      return order;
    } catch (e) {
      localStorage.removeItem('order');
      return;
    }
  }

  removeStorageOrder() {
    localStorage.removeItem('order');
  }

  getPostalCode() {
    return this._order.value.shippingAddress.postalCode;
  }
}


