import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {

  innerHTML = null;
  msg = null;
  mailToSupport = false;

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
  }

  closeDialog() {
    this.modalController.dismiss();
  }


}
