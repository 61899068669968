export async function executeWithRetry(_fn, options={data:null, maxCount:3, self:null}) {
  const { data, maxCount, self } = options;
  let retry = 0;
  let lastError;

  let res = null;
  while (retry < maxCount) {
    try {
      await new Promise(r => setTimeout(r, retry * getRandomIntInclusive(100, 200) )); // wait randomly...
      res = await _fn.call(self, data);
      break;
    } catch (err) {
      lastError = err;
      console.log('Error execute Retry: ' + err.message);
      retry++;
    }
    return res;
  }

  if ( retry == maxCount ) {
    res = lastError;
    console.error( new Error('ERROR NOTIFY: Execute call retries reached: ' + lastError.message) );
    console.error( lastError );
  }

  return res;
}

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}