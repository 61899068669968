import {Component, OnInit} from '@angular/core';
import {GoogleDrivePickerService} from '../../shared/services/googledrivepicker.service';
import {LoadingController, PopoverController} from '@ionic/angular';
import {DropboxPickerService} from '../../shared/services/dropbox-picker.service';

declare var gapi: any;
declare var google: any;
declare var Dropbox: any;

@Component({
  selector: 'app-upload-file-popover',
  templateUrl: './upload-file-popover.component.html',
  styleUrls: ['./upload-file-popover.component.scss'],
})
export class UploadFilePopoverComponent implements OnInit {

  loading: HTMLIonLoadingElement;

  constructor(private popoverController: PopoverController, private googleDrivePickerService: GoogleDrivePickerService,
              private dropboxPickerService: DropboxPickerService,
              private loadingController: LoadingController) {
  }

  async ngOnInit() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Cargando archivos...',
      spinner: 'bubbles'
    });
  }

  openGoogleDrivePicker(): void {
    this.googleDrivePickerService.open(async (data) => {
      if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
        this.loading.present();
        const docs = data[google.picker.Response.DOCUMENTS];
        const files = await this.googleDrivePickerService.getFiles(docs);
        this.loading.dismiss();
        this.popoverController.dismiss({files, done: true});
      }
    });
  }

  openDropboxPicker() {
    this.dropboxPickerService.open();
  }

  isDropboxSupported() {
    return Dropbox.isBrowserSupported();
  }
}
