// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close {
  font-size: 14px !important;
  --padding-end: 10px;
}

.img-product {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.description {
  font-size: 14px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb21vdGlvbi1kaWFsb2cuY29tcG9uZW50LnNjc3MiLCIuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXENvcHlmbHklMjAyXFxEZXNrdG9wXFxEZXNhcnJvbGxvXFxjb3Bpc3RlcmlhLWFwcC1pb25pY1xcc3JjXFxhcHBcXHNoYXJlZFxccHJvbW90aW9uLWRpYWxvZ1xccHJvbW90aW9uLWRpYWxvZy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLDBCQUFBO0VBQ0EsbUJBQUE7QUNDRjs7QURDQTtFQUNFLFlBQUE7RUFDQSxXQUFBO0VBQ0EsbUJBQUE7QUNFRjs7QURBQTtFQUNFLGVBQUE7QUNHRiIsImZpbGUiOiJwcm9tb3Rpb24tZGlhbG9nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmNsb3Nle1xyXG4gIGZvbnQtc2l6ZTogMTRweCAhaW1wb3J0YW50O1xyXG4gIC0tcGFkZGluZy1lbmQ6IDEwcHg7XHJcbn1cclxuLmltZy1wcm9kdWN0e1xyXG4gIGhlaWdodDogMTAwJTtcclxuICB3aWR0aDogMTAwJTtcclxuICBvYmplY3QtZml0OiBjb250YWluO1xyXG59XHJcbi5kZXNjcmlwdGlvbntcclxuICBmb250LXNpemU6IDE0cHg7XHJcbn0iLCIuY2xvc2Uge1xuICBmb250LXNpemU6IDE0cHggIWltcG9ydGFudDtcbiAgLS1wYWRkaW5nLWVuZDogMTBweDtcbn1cblxuLmltZy1wcm9kdWN0IHtcbiAgaGVpZ2h0OiAxMDAlO1xuICB3aWR0aDogMTAwJTtcbiAgb2JqZWN0LWZpdDogY29udGFpbjtcbn1cblxuLmRlc2NyaXB0aW9uIHtcbiAgZm9udC1zaXplOiAxNHB4O1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/shared/promotion-dialog/promotion-dialog.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/shared/promotion-dialog/promotion-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;EACA,mBAAA;ACCF;;ADCA;EACE,YAAA;EACA,WAAA;EACA,mBAAA;ACEF;;ADAA;EACE,eAAA;ACGF;ADCA,glCAAglC","sourcesContent":[".close{\r\n  font-size: 14px !important;\r\n  --padding-end: 10px;\r\n}\r\n.img-product{\r\n  height: 100%;\r\n  width: 100%;\r\n  object-fit: contain;\r\n}\r\n.description{\r\n  font-size: 14px;\r\n}",".close {\n  font-size: 14px !important;\n  --padding-end: 10px;\n}\n\n.img-product {\n  height: 100%;\n  width: 100%;\n  object-fit: contain;\n}\n\n.description {\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
