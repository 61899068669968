import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { PopoverController } from "@ionic/angular";

@Component({
  selector: 'app-tooltip-info-page-turn',
  templateUrl: './tooltip-info-page-turn.component.html',
  styleUrls: ['./tooltip-info-page-turn.component.scss'],
})
export class TooltipInfoPageTurnComponent implements OnInit, OnChanges {
  @Input() pageOrientation;
  longLottie;
  shortLottie;

  constructor(private popoverController: PopoverController) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes) {
    if (this.pageOrientation === 'vertical') {
      this.longLottie = 'assets/lotties/vertical-largo.json'
      this.shortLottie = 'assets/lotties/vertical-corto.json'
    } else {
      this.longLottie = 'assets/lotties/horizontal-largo.json'
      this.shortLottie = 'assets/lotties/horizontal-corto.json'
    }
    console.log('long', this.longLottie)
    console.log('short', this.shortLottie)
  }


}
