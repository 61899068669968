// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  font-weight: 600;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImluZGl2aWR1YWwtYmluZGluZy1vcHRpb25zLW1vZGFsLmNvbXBvbmVudC5zY3NzIiwiLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFxDb3B5Zmx5JTIwMlxcRGVza3RvcFxcRGVzYXJyb2xsb1xcY29waXN0ZXJpYS1hcHAtaW9uaWNcXHNyY1xcYXBwXFxjb21wb25lbnRzXFxpbmRpdmlkdWFsLWJpbmRpbmctb3B0aW9ucy1tb2RhbFxcaW5kaXZpZHVhbC1iaW5kaW5nLW9wdGlvbnMtbW9kYWwuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxrQ0FBQTtFQUNBLGVBQUE7RUFDQSxnQkFBQTtBQ0NGIiwiZmlsZSI6ImluZGl2aWR1YWwtYmluZGluZy1vcHRpb25zLW1vZGFsLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaDIge1xyXG4gIGZvbnQtZmFtaWx5OiBcIlJhbGV3YXlcIiwgc2Fucy1zZXJpZjtcclxuICBmb250LXNpemU6IDIycHg7XHJcbiAgZm9udC13ZWlnaHQ6IDYwMDtcclxufVxyXG4iLCJoMiB7XG4gIGZvbnQtZmFtaWx5OiBcIlJhbGV3YXlcIiwgc2Fucy1zZXJpZjtcbiAgZm9udC1zaXplOiAyMnB4O1xuICBmb250LXdlaWdodDogNjAwO1xufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/individual-binding-options-modal/individual-binding-options-modal.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/components/individual-binding-options-modal/individual-binding-options-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,kCAAA;EACA,eAAA;EACA,gBAAA;ACCF;ADCA,g2BAAg2B","sourcesContent":["h2 {\r\n  font-family: \"Raleway\", sans-serif;\r\n  font-size: 22px;\r\n  font-weight: 600;\r\n}\r\n","h2 {\n  font-family: \"Raleway\", sans-serif;\n  font-size: 22px;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
