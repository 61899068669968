// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  max-width: 50px;
}

ul {
  padding-left: 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtaW5mby1iaW5kaW5nLmNvbXBvbmVudC5zY3NzIiwiLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcQ29weWZseSUyMDJcXERlc2t0b3BcXERlc2Fycm9sbG9cXGNvcGlzdGVyaWEtYXBwLWlvbmljXFxzcmNcXGFwcFxcaG9tZS1uZXdcXHRvb2x0aXAtaW5mby1jb21wb25lbnRzXFx0b29sdGlwLWluZm8tYmluZGluZ1xcdG9vbHRpcC1pbmZvLWJpbmRpbmcuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxlQUFBO0FDQ0Y7O0FERUE7RUFDRSxrQkFBQTtBQ0NGIiwiZmlsZSI6InRvb2x0aXAtaW5mby1iaW5kaW5nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiaW1nIHtcclxuICBtYXgtd2lkdGg6IDUwcHg7XHJcbn1cclxuXHJcbnVsIHtcclxuICBwYWRkaW5nLWxlZnQ6IDIwcHg7XHJcbn1cclxuIiwiaW1nIHtcbiAgbWF4LXdpZHRoOiA1MHB4O1xufVxuXG51bCB7XG4gIHBhZGRpbmctbGVmdDogMjBweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/home-new/tooltip-info-components/tooltip-info-binding/tooltip-info-binding.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/home-new/tooltip-info-components/tooltip-info-binding/tooltip-info-binding.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;ACCF;;ADEA;EACE,kBAAA;ACCF;ADCA,gwBAAgwB","sourcesContent":["img {\r\n  max-width: 50px;\r\n}\r\n\r\nul {\r\n  padding-left: 20px;\r\n}\r\n","img {\n  max-width: 50px;\n}\n\nul {\n  padding-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
