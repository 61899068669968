// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-family: "Barlow Semi Condensed", sans-serif;
  font-weight: 400;
  font-size: 14px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInRvb2x0aXAtaW5mby1jb3Zlci1sYW1pbmF0ZWQuY29tcG9uZW50LnNjc3MiLCIuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFwuLlxcLi5cXC4uXFxDb3B5Zmx5JTIwMlxcRGVza3RvcFxcRGVzYXJyb2xsb1xcY29waXN0ZXJpYS1hcHAtaW9uaWNcXHNyY1xcYXBwXFxob21lLW5ld1xcdG9vbHRpcC1pbmZvLWNvbXBvbmVudHNcXHRvb2x0aXAtaW5mby1jb3Zlci1sYW1pbmF0ZWRcXHRvb2x0aXAtaW5mby1jb3Zlci1sYW1pbmF0ZWQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxnREFBQTtFQUNBLGdCQUFBO0VBQ0EsZUFBQTtBQ0NGIiwiZmlsZSI6InRvb2x0aXAtaW5mby1jb3Zlci1sYW1pbmF0ZWQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJwIHtcclxuICBmb250LWZhbWlseTogXCJCYXJsb3cgU2VtaSBDb25kZW5zZWRcIiwgc2Fucy1zZXJpZjtcclxuICBmb250LXdlaWdodDogNDAwO1xyXG4gIGZvbnQtc2l6ZTogMTRweDtcclxufVxyXG4iLCJwIHtcbiAgZm9udC1mYW1pbHk6IFwiQmFybG93IFNlbWkgQ29uZGVuc2VkXCIsIHNhbnMtc2VyaWY7XG4gIGZvbnQtd2VpZ2h0OiA0MDA7XG4gIGZvbnQtc2l6ZTogMTRweDtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/home-new/tooltip-info-components/tooltip-info-cover-laminated/tooltip-info-cover-laminated.component.scss","webpack://./../../../../Copyfly%202/Desktop/Desarrollo/copisteria-app-ionic/src/app/home-new/tooltip-info-components/tooltip-info-cover-laminated/tooltip-info-cover-laminated.component.scss"],"names":[],"mappings":"AAAA;EACE,gDAAA;EACA,gBAAA;EACA,eAAA;ACCF;ADCA,g5BAAg5B","sourcesContent":["p {\r\n  font-family: \"Barlow Semi Condensed\", sans-serif;\r\n  font-weight: 400;\r\n  font-size: 14px;\r\n}\r\n","p {\n  font-family: \"Barlow Semi Condensed\", sans-serif;\n  font-weight: 400;\n  font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
