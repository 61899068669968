export interface Coupon {
  id?: string;
  code: string;  // coupon code (always take to uppercase)
  message?: string;
  title?: string;
  description?: string; // admin reference, not visible to customers
  type: CouponType;
  amount: number;
  maxAmount?: number; //if type is percent, this represents the max absolute amount to discount.
  currency: string;  //amount currency
  valid: boolean;
  freeShipping?: boolean;
  bindingDiscountEnabled?: boolean;
  discountByRanges?: boolean;
  discountRanges?: any[];
}


export interface CouponTransaction {
  createdAt: Date;
  couponId: string;  // same as parent coupon
  couponCode: string;  // same as parent coupon
  userId: string;
  amount: number;  // actual amount used from the coupon
  currency: string;
  ref: DocReference; // reference to another document causing this transaction (usually, order)
}

export interface UserCoupon {
  createdAt: Date;
  // description: string; // admin reference, not visible to customers
  code: string;  // coupon code (always take to uppercase)
  type: CouponType;
  amount: number;
  maxAmount?: number;
  currency: string;  // absAmount currency

  // added 14/sept/2020
  title: string;
  firstPurchaseOnly?: boolean; // valid only on first purchase
  startDate?: any | Date;  // from when this coupon starts to be effective
  expirationDate?: any | Date;
}

export interface DocReference {
  id: string; // document id
  col: string; // collection
}

export enum CouponType {
  PERCENT = "PERCENT",
  FIXED = "FIXED"
}
