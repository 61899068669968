import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AppService } from '../shared/app.service';
import { ProductService } from '../shared/product.service';
import { ShippingService } from '../shared/services/shipping.service';
import { UserService } from './user.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class AppCoreService {
  private isAppReady = new BehaviorSubject<boolean>(false);
  public isAppReady$ = this.isAppReady.asObservable();
  private userService: UserService = inject(UserService);


  constructor(
    private productService: ProductService,
    private shippingService: ShippingService,
    private appService: AppService,
    private versionService: VersionService,

  ) { 
    this.init()
  }

  firstUser$ = this.userService.user$.pipe(first(user=> !!user?.uid))


  init(){
    combineLatest([this.productService.isInit$, this.shippingService.isInit$, this.appService.isInit$, this.firstUser$])
    .pipe(map(([productServiceInit, shippingServiceInit, appServiceInit, firstUser])=>
      productServiceInit && shippingServiceInit && appServiceInit && !!firstUser
    ))
    .subscribe(this.isAppReady);
  }


}

