import { Component, OnInit } from '@angular/core';
import { ModalController } from "@ionic/angular";
import { CartService } from "../cart.service";

@Component({
  selector: 'app-presale-warning',
  templateUrl: './presale-warning.component.html',
  styleUrls: ['./presale-warning.component.scss'],
})
export class PresaleWarningComponent implements OnInit {

  check = false;
  releaseDate = '';

  constructor(private modalCtrl: ModalController, private cartService: CartService) {
  }

  ngOnInit() {
    this.releaseDate = '22 de agosto'
  }

  closeModal(accept) {
    this.modalCtrl.dismiss(accept);
  }

  changeCheck() {
    this.check = !this.check;
  }
}
