import { Injectable } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { AddDocumentPopupComponent } from 'src/app/creators/add-document-popup/add-document-popup.component';
import { AddCartPopupComponent } from '../shop/add-cart-popup/add-cart-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ShopBlService {

  constructor(
    private popoverController: PopoverController,
    private modalController: ModalController,
  ){

  }

  // async selectProduct(productCard) {
  //   // console.log("PRDUCT: productCard",{...productCard});
  //   const popover = await this.popoverController.create({
  //     component: AddCartPopupComponent,
  //     componentProps: {
  //       productId: productCard.id
  //     },
  //     cssClass: 'popup-product-qty',
  //     translucent: false
  //   });
  //   // popover.onDidDismiss().then(response => {}
  //   // );

  //   return await popover.present();
  // }  
  async selectProduct(productCard, measurement = false) {
    // console.log("PRDUCT: productCard",{...productCard});
    const popover = await this.modalController.create({
      component: AddCartPopupComponent,
      componentProps: {
        productId: productCard.id,
        measurement
      },
      cssClass: 'auto-height',
      // cssClass: 'popup-product-qty',
      // translucent: false
    });
    // popover.onDidDismiss().then(response => {}
    // );

    return await popover.present();
  }  

  async selectDocument(productCard) {
    const popover = await this.modalController.create({
      component: AddDocumentPopupComponent,
      componentProps: {
        product: productCard
      },
      cssClass: 'popup-add-document'
    });
    popover.onDidDismiss()
      .then(response => {
        //TODO mandar una respuesta
        if(response){
          // console.log("ADD", response)
          // this.productBlService.addProduct(product, 1);
          // this.router.navigate(['/cart']);
        }
      }
    );

    return await popover.present();
  }  


  
}
